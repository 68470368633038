import NoData from "components/Lenses/common/NoData";
import { PDComponent } from "components/PDComponents";
import { selectListByTab, useLegendItems } from "hooks/intel/useLegendItems";
import type { IntelLegendVisibleTabs } from "pages/IntelDashboard/components/LegendGroupSelector/LegendGroupSelector";
import { IntelLegendColorsContext } from "pages/IntelDashboard/useIntelLegendColors";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";

import * as Styled from "./style";
import { Track } from "services/Mixpanel";

export const IntelLegend = ({
  onItemCountChange,
}: {
  onItemCountChange: (count: number) => void;
}) => {
  const { getOrCreateColor } = useContext(IntelLegendColorsContext);

  const selectedLegendTab = useAppSelector(
    (state) => state.intelDashboard.groupingType,
  );

  const { data: allListItems, isLoading: isLoadingListItems } = useLegendItems({
    select: selectListByTab,
  });

  const dispatch = useAppDispatch();
  const highlightedKeysCount = useAppSelector(
    (state) => state.intelDashboard.highlightedIds?.size ?? 0,
  );

  const handleOnLegendElementClick = useCallback(
    (name: string) => {
      if (highlightedKeysCount === 0) {
        Track.interact(
          `Intel Dashboard - Legend - Started Highlighting at least an item `,
        );
      }
      dispatch({
        type: "INTEL_DASHBOARD_TOGGLE_HIGHLIGHTED_KEY",
        payload: name,
      });
    },
    [dispatch, highlightedKeysCount],
  );

  const highlightedIds = useAppSelector(
    (state) => state.intelDashboard.highlightedIds,
  );

  const listItems = useMemo(() => {
    const list =
      allListItems?.[selectedLegendTab as IntelLegendVisibleTabs] ?? [];
    list.forEach((key, index) => getOrCreateColor({ key, index }));
    return list;
  }, [allListItems, selectedLegendTab, getOrCreateColor]);

  useEffect(() => {
    onItemCountChange((listItems ?? [])?.length);

    // Inside legend highlighted ids, whenever something in the list is changed in terms of numbers, prune the highlighted ids that are not in the list
    const listItemsSet = new Set(listItems);
    const differenceResult = highlightedIds?.difference(listItemsSet);

    if (differenceResult?.size) {
      differenceResult.forEach((key) => {
        dispatch({
          type: "INTEL_DASHBOARD_TOGGLE_HIGHLIGHTED_KEY",
          payload: key,
        });
      });
    }
  }, [dispatch, highlightedIds, listItems, onItemCountChange]);

  return (
    <Styled.LegendContainer>
      {isLoadingListItems ? null : listItems.length ? (
        <PDComponent.VirtualizedList
          items={listItems}
          itemSizePx={49}
          scrollbarDistance={0}
          overscanCount={2}
        >
          {(listItem, index) => (
            <Styled.LegendItem
              key={listItem}
              onClick={() => handleOnLegendElementClick(listItem)}
              $isHighlighted={!!highlightedIds?.has(listItem)}
            >
              <Styled.DotContainer>
                <Styled.ColoredDot
                  $backgroundColor={getOrCreateColor({
                    key: listItem,
                    index,
                  })}
                />
              </Styled.DotContainer>

              <Styled.LegendItemTitle title={listItem}>
                {listItem}
              </Styled.LegendItemTitle>
            </Styled.LegendItem>
          )}
        </PDComponent.VirtualizedList>
      ) : (
        <span
          // eslint-disable-next-line react/forbid-dom-props
          style={{
            height: "30%",
            width: "100%",
            alignItems: "center",
            display: "grid",
            gap: "0px",
          }}
        >
          <NoData
            isStatic
            message={
              <span>
                <b>No matching selections found.</b>
                <br />
                Please try another configuration.
              </span>
            }
          />
        </span>
      )}
    </Styled.LegendContainer>
  );
};
