import {
  type IntelRankingRibbonFactSetDto,
  type IntelRankingRibbonQuarterBarDto,
  type IntelRankingRibbonQuarterBarSliceDto,
  type IntelRankingRibbonQuarterBasSliceDiffDto,
  ResultDataState,
} from "apis/oag";
import { countBy } from "lodash";

export type IntelRankingRibbonVm = {
  name: string;
  quarter: string;
  quartile: number;
  rank: number;
  productivity: number;
  diff: IntelRankingRibbonQuarterBasSliceDiffDto | null;
};
export const PADDING_SLICE_NAME = "PADDING_SLICE";
const FlattenAndMapBarSlices = (
  bars: IntelRankingRibbonQuarterBarDto[],
  valueSelector: (
    bar: IntelRankingRibbonQuarterBarDto,
    slice: IntelRankingRibbonQuarterBarSliceDto,
  ) => number,
): IntelRankingRibbonVm[] => {
  return bars.reduce((acc, curr) => {
    const { quarter, year } = curr.quarterYear;
    const quarterStr = `Q${quarter} ${year.toString().slice(-2)}`;
    // we should backfill each of the quarter with the remaining slices for the quartile
    const calculatedSlices =
      curr.slices.length > 0
        ? curr.slices.map((v) => ({
            name: v.sliceName,
            quarter: quarterStr,
            quartile: v.quartile,
            rank: v.rank,
            productivity: valueSelector(curr, v),
            diff: v.diff ?? null,
          }))
        : [
            {
              name: "",
              quarter: quarterStr,
              quartile: 0,
              rank: 0,
              productivity: 0,
              diff: null,
            },
          ];

    return [...acc, ...calculatedSlices];
  }, [] as IntelRankingRibbonVm[]);
};

export const IntelRankingRibbonByProductivitySelector = (
  result: IntelRankingRibbonFactSetDto,
): IntelRankingRibbonVm[] => {
  if (result.dataState === ResultDataState.NoData) return [];
  return FlattenAndMapBarSlices(result.bars, (b, s) => s.productivity);
};

export const IntelRankingRibbonByQuartileSelector = (
  result: IntelRankingRibbonFactSetDto,
): IntelRankingRibbonVm[] => {
  if (result.dataState === ResultDataState.NoData) return [];

  // build count of each "Quarter -> Quartile" ahead for performance
  const quartilesCounts = result.bars.reduce<{
    [key: string]: { [key: number]: number };
  }>((acc, bar) => {
    acc[bar.quarterYear.key] = countBy(bar.slices, (s) => s.quartile);
    return acc;
  }, {});
  const getQuartileProductivity = (
    b: IntelRankingRibbonQuarterBarDto,
    s: IntelRankingRibbonQuarterBarSliceDto,
  ) => {
    if (s.sliceName === PADDING_SLICE_NAME) return s.productivity;
    return 0.25 / quartilesCounts[b.quarterYear.key][s.quartile];
  };

  const paddedBars = result.bars.reduce((acc, curr) => {
    if (curr.slices.length >= 4) return [...acc, curr];
    const paddingSlicesCount = 4 - curr.slices.length;
    return [
      ...acc,
      {
        ...curr,
        slices: [
          ...curr.slices,
          ...Array(paddingSlicesCount)
            .fill(null)
            .map((_, i) => {
              return {
                sliceName: PADDING_SLICE_NAME,
                quartile: 4 - i,
                rank: 4 - i,
                productivity:
                  (1 -
                    curr.slices.reduce(
                      (acc, v) => acc + getQuartileProductivity(curr, v),
                      0,
                    )) /
                  paddingSlicesCount,
                diff: undefined,
              };
            }),
        ],
      },
    ];
  }, [] as IntelRankingRibbonQuarterBarDto[]);

  return FlattenAndMapBarSlices(paddedBars, getQuartileProductivity);
};
