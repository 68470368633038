import type { WellIntelQueryDto } from "apis/oag";
import { useIntelLinkedFiltersByGroupAndQuarter } from "hooks/intel/useIntelLinkedFilters";
import { useCallback } from "react";
import { useStore } from "react-redux";
import type { appReducer } from "reducers/rootReducer";

export const useIntelFilterActions = () => {
  const store = useStore<ReturnType<typeof appReducer>>();
  const { data: filtersData } = useIntelLinkedFiltersByGroupAndQuarter();

  const getChangedFilterKeys = useCallback(() => {
    const uncommittedFilters = store.getState().intelFiltersUncommitted;
    const committedFilters = store.getState().intelFiltersCommitted;

    const differencesForEachKeys = (
      Object.keys(uncommittedFilters) as (keyof WellIntelQueryDto)[]
    )
      .filter((key) => key !== "grouping" && key !== "selectedQuarters")
      .map((key) => {
        const currentFilterGroupSet = new Set(uncommittedFilters[key]);
        const committedFilterGroupSet = new Set(committedFilters[key]);
        return currentFilterGroupSet.difference(committedFilterGroupSet)
          .size !== 0
          ? key
          : null;
      })
      .filter((key) => key !== null);

    // return only the keys that are different
    return differencesForEachKeys;
  }, [store]);

  const commitAllFilters = useCallback(() => {
    if (!filtersData) {
      return console.error(
        "Attempting to set filtersData but it is not available from useQuery",
      );
    }

    const changedFilterKeys = getChangedFilterKeys();
    const alreadyExistingCosmeticFilterOptions =
      store.getState().intelFiltersCosmeticOptions;

    if (changedFilterKeys.length !== 0) {
      // Reset all extra options for all filters
      store.dispatch({
        type: "INTEL_DASHBOARD_RESET_FILTERS_COSMETIC_OPTIONS",
      });
    }

    changedFilterKeys.forEach((key) => {
      // For the ones that changed, make sure to set the extra unselected options for the affected keys
      //   But the data is taken from the request data in order to store all of the "previous" options

      store.dispatch({
        type: "INTEL_DASHBOARD_SET_FILTER_COSMETIC_OPTIONS",
        payload: {
          key,
          value:
            alreadyExistingCosmeticFilterOptions[
              key as keyof WellIntelQueryDto
            ] ?? filtersData[key],
        },
      });
    });

    store.dispatch({
      type: "INTEL_DASHBOARD_SET_ALL_COMMITTED_FILTERS",
      payload: store.getState().intelFiltersUncommitted,
    });
  }, [filtersData, getChangedFilterKeys, store]);

  const setFilterKey = useCallback(
    (
      key: keyof WellIntelQueryDto,
      overwriteUncommittedStoreWith?:
        | WellIntelQueryDto[keyof WellIntelQueryDto]
        | null,
    ) => {
      const filterToCommit =
        overwriteUncommittedStoreWith ||
        store.getState().intelFiltersUncommitted[key];

      store.dispatch({
        type: "INTEL_DASHBOARD_SET_FILTER_KEY",
        payload: {
          key,
          value: filterToCommit,
        },
      });
    },
    [store],
  );

  const commitFilterKey = useCallback(
    (
      key: keyof WellIntelQueryDto,
      overwriteUncommittedStoreWith?:
        | WellIntelQueryDto[keyof WellIntelQueryDto]
        | null,
    ) => {
      const alreadyExistingCosmeticFilterOptions =
        store.getState().intelFiltersCosmeticOptions[key];

      const filterToCommit =
        overwriteUncommittedStoreWith ||
        store.getState().intelFiltersUncommitted[key];

      const changedFilterKeys = getChangedFilterKeys();

      const isChanged = changedFilterKeys.includes(
        key as (typeof changedFilterKeys)[number],
      );

      if (isChanged) {
        if (!filtersData) {
          return console.error(
            "Attempting to set filtersData but it is not available from useQuery",
          );
        }
        // Reset all extra options for all filters
        store.dispatch({
          type: "INTEL_DASHBOARD_RESET_FILTERS_COSMETIC_OPTIONS",
        });

        store.dispatch({
          type: "INTEL_DASHBOARD_SET_FILTER_COSMETIC_OPTIONS",
          payload: {
            key,
            value: alreadyExistingCosmeticFilterOptions ?? filtersData[key],
          },
        });
      }

      store.dispatch({
        type: "INTEL_DASHBOARD_SET_COMMITTED_FILTER",
        payload: {
          key,
          value: filterToCommit,
        },
      });

      store.dispatch({
        type: "INTEL_DASHBOARD_SET_FILTER_KEY",
        payload: {
          key,
          value: filterToCommit,
        },
      });
    },
    [filtersData, getChangedFilterKeys, store],
  );

  const removeFilterKey = useCallback(
    (key: keyof WellIntelQueryDto) => {
      store.dispatch({
        type: "INTEL_DASHBOARD_SET_COMMITTED_FILTER",
        payload: {
          key,
          value: null,
        },
      });

      store.dispatch({
        type: "INTEL_DASHBOARD_RESET_FILTER_KEY",
        payload: key,
      });
    },
    [store],
  );

  const commitPreselectedFilters = useCallback(
    (allAvailableFilters: WellIntelQueryDto) => {
      const preselectedFilters: Partial<WellIntelQueryDto> = {
        regions: ["PERMIAN BASIN"],
        countries: ["UNITED STATES"],
        basins: ["MIDLAND"],
      };

      Object.keys(preselectedFilters).forEach((key) => {
        store.dispatch({
          type: "INTEL_DASHBOARD_SET_COMMITTED_FILTER",
          payload: {
            key: key as keyof WellIntelQueryDto,
            value: preselectedFilters[key as keyof WellIntelQueryDto],
          },
        });

        store.dispatch({
          type: "INTEL_DASHBOARD_SET_FILTER_COSMETIC_OPTIONS",
          payload: {
            key: key as keyof WellIntelQueryDto,
            value: allAvailableFilters[key as keyof WellIntelQueryDto],
          },
        });
      });

      store.dispatch({
        type: "INTEL_SET_FILTERS_LIST_AS_AUTOSELECTED",
        payload: true,
      });
    },
    [store],
  );

  const resetFilterKey = useCallback(
    (key: keyof WellIntelQueryDto) => {
      store.dispatch({
        type: "INTEL_DASHBOARD_RESET_FILTER_KEY",
        payload: key,
      });
    },
    [store],
  );

  const emptyFilterKey = useCallback(
    (key: keyof WellIntelQueryDto) => {
      store.dispatch({
        type: "INTEL_DASHBOARD_EMPTY_FILTER_KEY",
        payload: key,
      });
    },
    [store],
  );

  const setAllFilters = useCallback(
    (filter: WellIntelQueryDto) => {
      store.dispatch({
        type: "INTEL_DASHBOARD_SET_ALL_UNCOMMITTED_FILTERS",
        payload: filter,
      });
    },
    [store],
  );

  const resetFiltersToInitialState = useCallback(() => {
    store.dispatch({
      type: "INTEL_DASHBOARD_SET_MAP_SELECTED_WELLS",
      payload: null,
    });

    store.dispatch({
      type: "INTEL_SET_QUARTER_YEAR_LIST_AS_AUTOSELECTED",
      payload: false,
    });

    store.dispatch({
      type: "INTEL_SET_FILTERS_LIST_AS_AUTOSELECTED",
      payload: false,
    });

    store.dispatch({
      type: "INTEL_DASHBOARD_COMMITTED_FILTERS_RESET_TO_INITIAL_STATE",
    });

    store.dispatch({
      type: "INTEL_DASHBOARD_FILTERS_RESET_TO_INITIAL_STATE",
    });

    store.dispatch({
      type: "INTEL_DASHBOARD_RESET_TO_INITIAL_STATE",
    });
  }, [store]);

  return {
    setFilterKey,
    commitAllFilters,
    commitFilterKey,
    commitPreselectedFilters,
    removeFilterKey,
    resetFilterKey,
    emptyFilterKey,
    setAllFilters,
    resetFiltersToInitialState,
  };
};
