import type {
  RigScorecardUserLensDto,
  ScorecardKpiCommentInfoDto,
  StandKpiType,
} from "apis/oag";
import { DimensionType, RigScorecardLensType } from "apis/oag";
import { Button } from "atoms/Form";
import { StyledTabs } from "components/Layout/Tabbed";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import { CardCommentEdit } from "components/RigScoreCardCommentsModal/CardCommentEdit";
import { SingleCommentBox } from "components/RigScoreCardCommentsModal/SingleCommentBox/SingleCommentBox";
import * as Styled from "components/RigScoreCardCommentsModal/styled";
import { CommentViewMode } from "components/RigScoreCardCommentsModal/utils";
import dayjs from "dayjs";
import { useUserLenses } from "hooks/lens/useUserLenses";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useScoreCardComments } from "hooks/rigs/scorecard/useScoreCardComments";
import { mergeWith } from "lodash";
import type { CustomTagProps } from "pages/RigScoreCard/LeftPane/components/CustomTag";
import { CustomTag } from "pages/RigScoreCard/LeftPane/components/CustomTag";
import { StyledTagsSpace } from "pages/RigScoreCard/LeftPane/components/GroupTitle";
import {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { Track } from "services/Mixpanel";
import { Space, Tooltip } from "utils/componentLibrary";
import { SHORT_DATE_FORMAT, SHORTER_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

export interface RigScoreCardCommentsModalProps {
  kpiName: string;
  kpiType: StandKpiType;
  lensId: number;
  navigateToDetailedView: () => string;
  onClose: () => void;
  rigName: string;
  tags: CustomTagProps[];
  wellId: number;
  wellName: string;
  wellNumber: number;
  kpiLabels: (JSX.Element | null)[];
  baseValue: string | null;
  targetValue: string | null;
  unformattedValue: number;
  hasDrillerComments?: boolean;
  kpiDescription?: string;
}

// TODO unsure why we need both CommentsModalInfoType and RigScoreCardCommentsModalProps
export const RigScoreCardCommentsModal: React.FC<
  RigScoreCardCommentsModalProps
> = ({
  kpiType,
  tags,
  targetValue,
  baseValue,
  kpiName,
  kpiDescription,
  lensId,
  navigateToDetailedView,
  onClose,
  rigName,
  wellId,
  wellName,
  wellNumber,
  kpiLabels,
  unformattedValue,
  hasDrillerComments = true,
}) => {
  const [modalVisible, setModalVisible] = useState(true);
  const [currentlyEditedComment, setCurrentlyEditedComment] =
    useState<ScorecardKpiCommentInfoDto | null>(null);
  const [commentViewMode, setCommentViewMode] = useState(CommentViewMode.LIST);

  const [rigSelectedWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.SELECTED_WELLS_RIG_SCORECARD,
    [],
  );

  const { data: commentsByLens, refetch: refetchComments } =
    useScoreCardComments(rigSelectedWells);

  const commentListRef = useRef<HTMLDivElement>(null);

  const { data: lenses } = useUserLenses<RigScorecardUserLensDto>();

  const [scorecardType, acceptsExceptions] = useMemo(() => {
    const thisLens = lenses?.byId[lensId];
    if (!thisLens) {
      throw new Error(
        `Lens with id ${lensId} not found, in RigScoreCardCommentsModal`,
      );
    }
    return [
      thisLens?.scorecardType ?? RigScorecardLensType.Default,
      thisLens?.acceptsExceptions ?? false,
    ];
  }, [lensId, lenses?.byId]);

  useLayoutEffect(() => {
    if (commentListRef?.current) {
      commentListRef.current.scrollTop = commentListRef.current?.scrollHeight;
    }
  }, [commentsByLens]);

  const distanceUOM = useUOM(DimensionType.Metres);

  const comments = useMemo(() => {
    return (
      (commentsByLens ?? [])
        .find((commentGroup) => commentGroup.scorecardLensId === lensId)
        ?.comments.filter((comm) => comm.wellId === wellId) || []
    );
  }, [commentsByLens, lensId, wellId]);

  const commentsById = useMemo(() => {
    return commentsByLens.reduce<Record<number, number>>(
      (acc, commentByLens) => {
        const mappedComments = commentByLens.comments.reduce<
          Record<number, number>
        >((accMap, comment) => {
          const lens = lenses?.byId[commentByLens.scorecardLensId];
          if (
            lens?.scorecardType !== RigScorecardLensType.OverallWellScores &&
            lens?.scorecardType !== RigScorecardLensType.Default
          ) {
            return accMap[comment.wellId]
              ? {
                  ...accMap,
                  [comment.wellId]: accMap[comment.wellId] + 1,
                }
              : {
                  ...accMap,
                  [comment.wellId]: 1,
                };
          }
          return accMap;
        }, {});
        return mergeWith(acc, mappedComments, (objValue, srcValue) => {
          return objValue ? objValue + srcValue : srcValue;
        });
      },
      {},
    );
  }, [commentsByLens, lenses]);

  const standKpiComments = useMemo(() => {
    return (
      (commentsByLens ?? [])
        .find((commentGroup) => commentGroup.scorecardLensId === lensId)
        ?.standComments.filter(
          (comm) => comm.drillingStand.wellId === wellId,
        ) || []
    );
  }, [commentsByLens, lensId, wellId]);

  useEffect(() => {
    if (
      commentViewMode === CommentViewMode.POST ||
      commentViewMode === CommentViewMode.LIST
    ) {
      setCurrentlyEditedComment(null);
    }
  }, [commentViewMode]);

  const handleOnAddNoteClick = useCallback(() => {
    Track.clickEvent("Rig Scorecard - Add comment", {
      "Rig Name": rigName,
      "Well Id": wellId,
      "Lens Id": lensId,
      "Well Name": wellName,
    });
    setCurrentlyEditedComment(null);
    setCommentViewMode(CommentViewMode.POST);
  }, [rigName, wellId, wellName, lensId]);

  const handleOnEditModeClose = useCallback(
    async (shouldRefresh: boolean) => {
      setCommentViewMode(CommentViewMode.LIST);
      if (shouldRefresh) {
        await refetchComments();
      }
    },
    [refetchComments],
  );

  const canAddExceptions = useMemo(
    () => comments.every((comment) => !comment.exceptionStatus),
    [comments],
  );

  const standComments = useMemo(
    () => (
      <Styled.StandCommentMain>
        {standKpiComments.map((standComment) => (
          <Styled.CommentContainer $paddingTop={32} key={standComment.id}>
            <Styled.ExistingComment>
              <Styled.CommentHeader>
                <Styled.CommentHeaderDescription>
                  <Styled.DateText>
                    {dayjs(standComment?.createdAt?.utc).format(
                      SHORT_DATE_FORMAT,
                    )}
                  </Styled.DateText>
                  <Styled.Text>{standComment.authorDisplayName}</Styled.Text>
                </Styled.CommentHeaderDescription>
                <Styled.CommentHeaderDescriptionRight>
                  <Styled.DateText>Stand depth</Styled.DateText>
                  <Styled.Text>
                    {distanceUOM.display(
                      standComment.drillingStand?.standNumber,
                      { fractionDigits: 0 },
                    )}
                  </Styled.Text>
                </Styled.CommentHeaderDescriptionRight>
              </Styled.CommentHeader>
              <Styled.ExistingCommentContents>
                {standComment.description}
              </Styled.ExistingCommentContents>
              {standComment.startRangeDrillingStand &&
              standComment.endRangeDrillingStand ? (
                <Styled.ExistingCommentContents>
                  <PDComponent.SvgIcon name="multiStand" />
                  <strong> Multi Stand</strong>
                  <Styled.FadedText>{`${distanceUOM.display(
                    standComment.startRangeDrillingStand?.standNumber,
                    {
                      fractionDigits: 0,
                    },
                  )} to ${distanceUOM.display(
                    standComment.endRangeDrillingStand?.standNumber,
                    {
                      fractionDigits: 0,
                    },
                  )} ${formatTime(
                    standComment?.startRangeDrillingStand?.startAt,
                    {
                      formatStr: SHORTER_DATE_FORMAT,
                    },
                  )} to ${formatTime(
                    standComment?.endRangeDrillingStand?.endAt,
                    {
                      formatStr: SHORTER_DATE_FORMAT,
                    },
                  )}`}</Styled.FadedText>
                </Styled.ExistingCommentContents>
              ) : null}
            </Styled.ExistingComment>
          </Styled.CommentContainer>
        ))}
      </Styled.StandCommentMain>
    ),
    [distanceUOM, standKpiComments],
  );
  const scorecardNotes = useMemo(
    () => (
      <div>
        <Styled.CommentListContainer ref={commentListRef}>
          {commentViewMode !== CommentViewMode.LIST ? (
            <CardCommentEdit
              editedComment={currentlyEditedComment}
              onEditModeClose={handleOnEditModeClose}
              wellId={wellId}
              lensId={lensId}
              kpiType={kpiType}
              scorecardType={scorecardType}
              canUseExceptions={acceptsExceptions}
              canAddExceptions={canAddExceptions}
              maximumExceptionTime={unformattedValue}
              isCommentGenerationAvailable
              numStandKpiComments={standKpiComments.length}
              numCommentsForWell={commentsById[wellId] ?? 0}
            />
          ) : (
            <>
              {comments.map((comment, idx) => (
                <SingleCommentBox
                  comment={comment}
                  commentViewMode={commentViewMode}
                  key={comment.id ?? idx}
                  setCurrentlyEditedComment={setCurrentlyEditedComment}
                  setCommentViewMode={setCommentViewMode}
                />
              ))}
            </>
          )}
        </Styled.CommentListContainer>

        {commentViewMode === CommentViewMode.LIST ? (
          <Styled.AddComment>
            <Styled.PlusIcon onClick={handleOnAddNoteClick} name="addFilled" />
            <Styled.AddNoteText onClick={handleOnAddNoteClick}>
              Add Note
            </Styled.AddNoteText>
          </Styled.AddComment>
        ) : null}
      </div>
    ),
    [
      commentsById,
      standKpiComments.length,
      acceptsExceptions,
      canAddExceptions,
      commentViewMode,
      comments,
      currentlyEditedComment,
      handleOnAddNoteClick,
      handleOnEditModeClose,
      kpiType,
      lensId,
      scorecardType,
      unformattedValue,
      wellId,
    ],
  );

  const commentTabs = useMemo(
    () =>
      [
        {
          label: `Notes (${comments.length})`,
          key: "notes",
          children: scorecardNotes,
        },
        hasDrillerComments
          ? {
              label: `Driller Comments (${standKpiComments.length})`,
              key: "comments",
              children: standComments,
            }
          : {},
      ].filter((x) => x) as {
        label: string;
        key: string;
        children: JSX.Element;
      }[],
    [
      comments.length,
      hasDrillerComments,
      scorecardNotes,
      standComments,
      standKpiComments.length,
    ],
  );
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  return (
    <Styled.ModalWrapper
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      width={610}
      centered
      bodyStyle={{ padding: 0, backgroundColor: themeColors.primary_bg }}
      onAfterClose={() => {
        setCommentViewMode(CommentViewMode.LIST);
        onClose();
      }}
    >
      <Styled.ModalBody>
        <Suspense fallback={<Loader withWrapper zIndex={zIndexLayer.above} />}>
          <Styled.Container>
            <Styled.Description>
              <Styled.WellNumber>#{wellNumber}</Styled.WellNumber>
              <Styled.WellName>{wellName}</Styled.WellName>•
              <Styled.RigName>{rigName}</Styled.RigName>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div>
                    <Styled.Kpi>
                      <div style={{ display: "grid" }}>
                        <span>{kpiName}</span>
                        {kpiDescription ? (
                          <span style={{ marginTop: "-2px" }}>
                            {kpiDescription}
                          </span>
                        ) : null}
                      </div>
                    </Styled.Kpi>
                  </div>
                  <div></div>
                  <div>
                    <Styled.StyledSeparator>
                      {kpiLabels.map((val, idx) => (
                        <Styled.Kpi key={idx} $isWhite="true">
                          <Space>{val}</Space>
                        </Styled.Kpi>
                      ))}
                    </Styled.StyledSeparator>
                  </div>

                  {!(targetValue && baseValue) ? null : (
                    <Styled.StyledSeparator>
                      <Styled.Kpi>
                        <Space>Target: {targetValue}</Space>
                      </Styled.Kpi>
                      <Styled.Kpi>
                        <Space>Base Value: {baseValue}</Space>
                      </Styled.Kpi>
                    </Styled.StyledSeparator>
                  )}
                  <Styled.Scores>
                    <StyledTagsSpace $withTagSeparators>
                      {tags.map((tag, index) => (
                        <CustomTag key={index} {...tag} />
                      ))}
                    </StyledTagsSpace>
                  </Styled.Scores>
                </div>

                {!navigateToDetailedView() ? null : (
                  <div style={{ alignSelf: "flex-end", marginBottom: "6px" }}>
                    <Tooltip title="Open in a new tab">
                      <Link to={navigateToDetailedView()} target="_blank">
                        <Button
                          size="middle"
                          style={{
                            fontSize: "14px",
                          }}
                          type="primary"
                          onClick={() =>
                            Track.interact("Navigate - Detailed View")
                          }
                        >
                          Detailed View
                          <PDComponent.SvgIcon name="newTab" />
                        </Button>
                      </Link>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Styled.Description>
            <Styled.TabsContainer>
              <StyledTabs
                type="card"
                tabBarStyle={{ margin: 0 }}
                tabBarGutter={6}
                items={commentTabs}
              />
            </Styled.TabsContainer>
          </Styled.Container>
        </Suspense>
      </Styled.ModalBody>
    </Styled.ModalWrapper>
  );
};
