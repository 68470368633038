import { Button } from "atoms/Form";
import { darkButtonCss } from "commonStyles";
import styled from "styled-components";
import colors from "utils/colors";

export const HeaderMap = styled.div`
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const ResetButton = styled(Button)`
  letter-spacing: -0.2px;
  ${darkButtonCss}
  color: ${colors.white} !important;
  &.ant-btn[disabled] {
    background-color: ${colors.limo_scene} !important;
    color: ${colors.gray} !important;
  }
`;

export const ApplyButton = styled(Button)`
  letter-spacing: -0.2px;
  &.ant-btn:hover,
  &.ant-btn:focus {
    background-color: ${colors.shutter_black} !important;
    color: ${colors.white} !important;
  }

  &.ant-btn[disabled] {
    background-color: ${colors.limo_scene} !important;
    color: ${colors.gray} !important;
  }

  border-color: ${colors.limo_scene} !important;
`;
