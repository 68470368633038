import styled from "styled-components";
import colors from "utils/colors";

export const DividerContainer = styled.div`
  width: 25px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DividerLine = styled.div`
  background-color: ${({ theme: { isDark } }) =>
    isDark ? colors.limo_scene : colors.widget_line};
  width: 1px;
  height: 100%;
`;
