import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { IntelWellInfoSetDto } from "apis/oag";
import { FiltersApi, IntelGroupingType } from "apis/oag";
import { UNFILTERED_SELECTIONS } from "reducers/intelFiltersReducerUncommitted";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { RequestUID } from "utils/queryNamespaces";

const filtersApi = new FiltersApi(apiConfig);

export const useIntelWells = <T = IntelWellInfoSetDto,>(
  options?: Partial<UseQueryOptions<IntelWellInfoSetDto, Error, T>>,
) => {
  const [selectedQuarters, isEnabled] = useAppSelector((state) => [
    state.intelFiltersCommitted.selectedQuarters,
    state.intelDashboard.hasAutoSelectedQuarterYearList &&
      state.intelDashboard.hasAutoSelectedFilters,
  ]);

  return useQuery({
    queryKey: [{ uid: RequestUID.intelWells }],
    queryFn: () =>
      filtersApi.apiFiltersIntelWellsPut({
        wellIntelQueryDto: {
          ...UNFILTERED_SELECTIONS,
          selectedQuarters,
          grouping: IntelGroupingType.Rig,
        },
      }),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: isEnabled,
    ...options,
  });
};
