import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import type { ScorecardKpiCommentDto, StandKpiType } from "apis/oag";
import { RigScorecardLensType } from "apis/oag";
import { RigCardDataType, ScorecardKpiCommentsApi, TimeUnit } from "apis/oag";
import type { ScorecardKpiCommentInfoDto } from "apis/oag/models/ScorecardKpiCommentInfoDto";
import { Button } from "atoms/Form";
import { ChatLoader } from "components/Clara/Loaders/ChatLoader";
import { RobotLoader } from "components/Clara/Loaders/RobotLoader";
import { PDComponent } from "components/PDComponents";
import { FormItem } from "components/RigScoreCardCommentsModal/FormItem";
import * as Styled from "components/RigScoreCardCommentsModal/styled";
import { useCommentGenerate } from "hooks/clara/useCommentGenerate";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { useApprovers } from "hooks/rigs/scorecard/useApprovers";
import { RigScorecardInvalidationKey } from "hooks/rigs/scorecard/useRigScorecardCardFacts";
import { CommentsInvalidationKey } from "hooks/rigs/scorecard/useScoreCardComments";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import {
  BenchmarkType,
  useScoreBenchmarkContext,
} from "pages/RigScoreCard/ScoreBenchmarkContext";
import type { ChangeEvent } from "react";
import { useCallback, useMemo, useState } from "react";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { Col, Input, Row, Tooltip } from "utils/componentLibrary";
import { SECONDS_IN_HOUR, useTimeUom } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

const scorecardCommentApi = new ScorecardKpiCommentsApi(apiConfig);

const exceptionText = "An exception has already been set for this KPI.";

interface CardCommentEditProps {
  kpiType: StandKpiType;
  scorecardType: RigScorecardLensType;
  wellId: number;
  lensId: number;
  editedComment?: ScorecardKpiCommentInfoDto | null;
  maximumExceptionTime?: number;
  canUseExceptions?: boolean;
  canAddExceptions?: boolean;
  isCommentGenerationAvailable?: boolean;
  numStandKpiComments?: number;
  numCommentsForWell?: number;
  onEditModeClose: (shouldRefresh: boolean) => void;
  paddingTop?: number;
}

export const CardCommentEdit: React.FC<CardCommentEditProps> = ({
  editedComment,
  wellId,
  lensId,
  scorecardType,
  kpiType,
  onEditModeClose,
  canUseExceptions,
  paddingTop = 32,
  canAddExceptions = false,
  maximumExceptionTime,
  numStandKpiComments,
  numCommentsForWell,
  isCommentGenerationAvailable = false,
}) => {
  const [currentEditedText, setCurrentEditedText] = useState(
    editedComment?.description || "",
  );
  const queryClient = useQueryClient();
  const { data: approvers } = useApprovers(wellId);
  const [isLoading, setIsLoading] = useState(false);
  const [isExceptionEnabled, setIsExceptionEnabled] = useState(
    canUseExceptions && !!editedComment?.exceptionApproverId,
  );
  const [exceptionTime, setExceptionTime] = useState<number | null>(
    editedComment?.exceptionTime || 0,
  );

  const isCommentGenerationDisabled =
    scorecardType === RigScorecardLensType.OverallWellScores
      ? !numCommentsForWell
      : (numStandKpiComments ?? 0) <= 0;

  const { data: wellShortInfo } = useWellShortInfoSuspended();
  const hoursUOM = useTimeUom(TimeUnit.Hour);
  const { data: kpiTypes } = useKpiTypes();
  const sortedApprovers = useMemo(() => {
    return (approvers ?? []).sort((a, b) => {
      if ((a.firstName || "") > (b.firstName || "")) return 1;
      if ((a.firstName || "") < (b.firstName || "")) return -1;
      return 0;
    });
  }, [approvers]);

  const [approver, setApprover] = useState(
    editedComment?.exceptionApproverId
      ? (approvers ?? []).find(
          (approver) => approver?.id === editedComment?.exceptionApproverId,
        )
      : undefined,
  );

  const exceptionError = useMemo(() => {
    if (
      (exceptionTime && exceptionTime > (maximumExceptionTime || 0)) ||
      exceptionTime === null ||
      exceptionTime <= 0
    ) {
      return "Exception Hours must be between 0 and the actual KPI value";
    }
  }, [exceptionTime, maximumExceptionTime]);

  const handleCommentPost = useMutation({
    mutationFn: (text: string) => {
      if (!text || text.length === 0) {
        return new Promise((resolve) => resolve(null));
      }

      Track.interact("Rig Scorecard - Add Note", {
        "Well Id": wellId,
        "Well Name": wellShortInfo?.byId[wellId]?.name || "",
        "Kpi Type": kpiTypes?.byId?.[kpiType]?.shortName || "",

        ...(isExceptionEnabled
          ? {
              Approver: `${approver?.firstName} ${approver?.lastName}`,
              "Exception Time": hoursUOM.display(exceptionTime, {
                fractionDigits: 2,
              }),
            }
          : {}),
      });
      return scorecardCommentApi.apiScorecardKpiCommentsPost({
        scorecardKpiCommentDto: {
          wellId,
          kpiType,
          scorecardType,
          description: text,
          id: 0,
          exceptionApproverId: isExceptionEnabled ? approver?.id : undefined,
          exceptionTime: isExceptionEnabled ? exceptionTime : undefined,
        },
      });
    },
  });

  const handleCommentEdit = useMutation({
    mutationFn: (text: string) => {
      if (
        !text ||
        text.length === 0 ||
        !Number.isFinite(editedComment?.id) ||
        !editedComment
      ) {
        return new Promise((resolve) => resolve(null));
      }

      Track.interact("Rig Scorecard - Edit Note", {
        "Well Id": wellId,
        "Well Name": wellShortInfo?.byId[wellId]?.name || "",
        "Kpi Type": kpiTypes?.byId?.[kpiType]?.shortName || "",
        "Comment Id": editedComment?.id,
        ...(isExceptionEnabled
          ? {
              Approver: `${approver?.firstName} ${approver?.lastName}`,
              "Exception Time": hoursUOM.display(exceptionTime, {
                fractionDigits: 2,
              }),
            }
          : {}),
      });

      return scorecardCommentApi.apiScorecardKpiCommentsIdPut({
        id: editedComment.id,

        scorecardKpiCommentDto: {
          wellId: wellId,
          description: text,
          id: editedComment.id,
          kpiType: editedComment.kpiType,
          scorecardType: editedComment.scorecardType,
          exceptionApproverId: isExceptionEnabled ? approver?.id : undefined,
          exceptionTime: isExceptionEnabled ? exceptionTime : undefined,
        },
      });
    },
  });

  const handleOnSendButtonClick = useCallback(async () => {
    setIsLoading(true);
    if (editedComment) {
      Track.interact("Rig Scorecard - Save Edited Comment");
      await handleCommentEdit.mutateAsync(currentEditedText);
    } else {
      await handleCommentPost.mutateAsync(currentEditedText);
    }
    queryClient.invalidateQueries({ queryKey: [RigScorecardInvalidationKey] });
    queryClient.invalidateQueries({ queryKey: [CommentsInvalidationKey] });
    setIsLoading(false);
    onEditModeClose(true);
  }, [
    currentEditedText,
    editedComment,
    handleCommentEdit,
    handleCommentPost,
    onEditModeClose,
    queryClient,
  ]);

  const handleOnApproverChange = useCallback(
    (optionValue: number) => {
      setApprover(
        (approvers ?? []).find((approver) => approver?.id === optionValue),
      );
    },
    [approvers],
  );

  const handleOnExceptionHoursChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) {
        setExceptionTime(
          Number.isFinite(+e.target.value)
            ? +e.target.value * SECONDS_IN_HOUR
            : null,
        );
      } else setExceptionTime(null);
    },
    [],
  );

  const handleOnExceptionCheckboxChange = useCallback(
    (e: CheckboxChangeEvent) => {
      setIsExceptionEnabled(e.target.checked);
    },
    [],
  );

  const isSubmitDisabled = useMemo(() => {
    return (
      !currentEditedText.length ||
      !!(isExceptionEnabled && exceptionError) ||
      (isExceptionEnabled && !approver?.id)
    );
  }, [
    approver?.id,
    currentEditedText.length,
    exceptionError,
    isExceptionEnabled,
  ]);

  const { generateComment, isGenerating, isSuccess, isError } =
    useCommentGenerate({
      onSuccess: useCallback((data?: ScorecardKpiCommentDto) => {
        if (data) {
          setCurrentEditedText(data.title ?? "");
        }
      }, []),
    });
  const { isDark } = useCustomTheme();

  const { icon, title } = useMemo(() => {
    if (isGenerating) {
      return {
        icon: <RobotLoader size={24} />,
        title: "Working on your summary now",
      };
    }
    if (isError) {
      return {
        icon: (
          <PDComponent.SvgIcon
            name={
              isDark
                ? "claraErrorTransparentDark"
                : "claraErrorTransparentLight"
            }
            width={24}
            height={24}
          />
        ),
        title: "Try Again",
      };
    }
    return {
      icon: (
        <PDComponent.SvgIcon
          name={isDark ? "claraTransparentDark" : "claraTransparentLight"}
          width={24}
          height={24}
        />
      ),
      title: "Generate by Clara",
    };
  }, [isGenerating, isError, isDark]);

  const isGeneratingButtonVisible = useMemo(
    () =>
      scorecardType !== RigScorecardLensType.Default &&
      isCommentGenerationAvailable,
    [scorecardType, isCommentGenerationAvailable],
  );

  const { scoreBenchmark } = useScoreBenchmarkContext();

  const canEditLeftPane = useMemo(() => {
    return canAddExceptions || editedComment?.exceptionStatus;
  }, [canAddExceptions, editedComment]);

  return (
    <Styled.CommentContainer $paddingTop={paddingTop}>
      <Styled.CommentIconContainer>
        <Styled.CommentIcon>
          <PDComponent.SvgIcon name="chat" />
        </Styled.CommentIcon>
      </Styled.CommentIconContainer>

      <Styled.NewComment>
        {isGenerating ? (
          <Styled.SkeletonContainer>
            <Styled.LoadingSkeleton
              active
              paragraph={{
                rows: 9,
              }}
              title={false}
            />
          </Styled.SkeletonContainer>
        ) : (
          <Input.TextArea
            maxLength={4000}
            onChange={(e) => setCurrentEditedText(e.target.value)}
            autoFocus
            defaultValue={currentEditedText}
            value={currentEditedText}
            onFocus={(e) =>
              e.target.setSelectionRange(
                e.target.value.length,
                e.target.value.length,
              )
            }
          />
        )}

        <Styled.CommentActions>
          <Styled.CommentActionsLeft>
            {isGeneratingButtonVisible ? (
              <Styled.CommentGeneratorContainer>
                {isSuccess ? (
                  <Styled.SuccessText>
                    Clara’s summary is ready. Modify or generate a new note as
                    needed.
                  </Styled.SuccessText>
                ) : null}
                {isError ? (
                  <Styled.ErrorText>
                    Oops! Something went wrong, we couldn’t generate the
                    summary. Please try again.
                  </Styled.ErrorText>
                ) : null}

                <div>
                  <Tooltip
                    title={
                      isCommentGenerationDisabled ? (
                        <Styled.TooltipText $color={colors.white}>
                          {scorecardType ===
                          RigScorecardLensType.OverallWellScores
                            ? "Cannot create a note as no comments are available for other KPIs."
                            : "Cannot create a note as there are no driller comments."}
                        </Styled.TooltipText>
                      ) : null
                    }
                    placement="topLeft"
                    overlayStyle={{ maxWidth: "500px" }}
                  >
                    <Styled.GeneratorButtonContainer
                      $isGenerating={isGenerating}
                      $disabled={isCommentGenerationDisabled}
                    >
                      <Styled.GeneratorButton
                        icon={
                          <Styled.IconContainer $isError={isError}>
                            {icon}
                          </Styled.IconContainer>
                        }
                        disabled={isCommentGenerationDisabled}
                        onClick={() => {
                          if (isGenerating) return;
                          Track.interact("Rig Scorecard - Generate by Clara", {
                            "Well Id": wellId,
                            "Well Name":
                              wellShortInfo?.byId[wellId]?.name || "",
                            "Score Type":
                              scoreBenchmark === BenchmarkType.OperatorTarget
                                ? RigCardDataType.OperatorScore
                                : RigCardDataType.PdBenchmarkValue,
                            ...(scorecardType ===
                            RigScorecardLensType.OverallWellScores
                              ? {
                                  "Kpi Type": "Overall Well Scores",
                                  "Total Notes for well": numCommentsForWell,
                                }
                              : {
                                  "Total Driller Comments": numStandKpiComments,
                                  "Kpi Type":
                                    kpiTypes?.byId?.[kpiType]?.name || "",
                                }),
                          });
                          generateComment({
                            lensId,
                            wellId,
                            useExceptions: true,
                            scoreType:
                              scoreBenchmark === BenchmarkType.OperatorTarget
                                ? RigCardDataType.OperatorScore
                                : RigCardDataType.PdBenchmarkValue,
                          });
                        }}
                      >
                        <Styled.ButtonText>
                          {title}
                          {isGenerating ? <ChatLoader size={24} /> : null}
                        </Styled.ButtonText>
                      </Styled.GeneratorButton>
                    </Styled.GeneratorButtonContainer>
                  </Tooltip>
                </div>
              </Styled.CommentGeneratorContainer>
            ) : null}
            {canUseExceptions ? (
              <Styled.ExceptionsContainer $isDisabled={!canEditLeftPane}>
                {!canEditLeftPane ? (
                  <Tooltip
                    overlayStyle={{ maxWidth: "500px" }}
                    title={
                      <Styled.TooltipText>{exceptionText}</Styled.TooltipText>
                    }
                    placement="topLeft"
                  >
                    <Row
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                      }}
                    >
                      <Col>
                        <Styled.ExceptionCheckbox
                          checked={isExceptionEnabled}
                          id="exceptionCheckbox"
                          name="exceptionCheckbox"
                          onChange={handleOnExceptionCheckboxChange}
                          disabled
                        />
                      </Col>
                      <Col>
                        <Styled.ExceptionContainer>
                          <Styled.ExceptionLabel htmlFor="exceptionCheckbox">
                            This is an exception.
                          </Styled.ExceptionLabel>
                          <FormItem
                            {...{
                              isExceptionEnabled: !!isExceptionEnabled,
                              exceptionTime: exceptionTime || 0,
                              handleOnExceptionHoursChange,
                              exceptionError: exceptionError || "",
                              approver,
                              handleOnApproverChange,
                              approvers: sortedApprovers,
                            }}
                          />
                        </Styled.ExceptionContainer>
                      </Col>
                    </Row>
                  </Tooltip>
                ) : (
                  <Row
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto 1fr",
                    }}
                  >
                    <Col>
                      <Styled.ExceptionCheckbox
                        checked={isExceptionEnabled}
                        id="exceptionCheckbox"
                        name="exceptionCheckbox"
                        onChange={handleOnExceptionCheckboxChange}
                      />
                    </Col>
                    <Col>
                      <Styled.ExceptionContainer>
                        <Styled.ExceptionLabel htmlFor="exceptionCheckbox">
                          This is an exception.
                        </Styled.ExceptionLabel>
                        <FormItem
                          {...{
                            isExceptionEnabled: !!isExceptionEnabled,
                            exceptionTime: exceptionTime || 0,
                            handleOnExceptionHoursChange,
                            exceptionError: exceptionError || "",
                            approver,
                            handleOnApproverChange,
                            approvers: sortedApprovers,
                          }}
                        />
                      </Styled.ExceptionContainer>
                    </Col>
                  </Row>
                )}
              </Styled.ExceptionsContainer>
            ) : null}
          </Styled.CommentActionsLeft>
          <Styled.CommentActionsRight>
            <Button
              icon={<PDComponent.SvgIcon name="closeOutline" />}
              onClick={() => onEditModeClose(false)}
            ></Button>
            <Button
              loading={isLoading}
              disabled={isSubmitDisabled}
              onClick={handleOnSendButtonClick}
              icon={
                <PDComponent.SvgIcon
                  name="sendFilled"
                  style={{
                    fontSize: 18,
                    color: isSubmitDisabled
                      ? colors.widget_line
                      : colors.well_color,
                  }}
                />
              }
            ></Button>
          </Styled.CommentActionsRight>
        </Styled.CommentActions>
      </Styled.NewComment>
    </Styled.CommentContainer>
  );
};
