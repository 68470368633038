/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntelRankingRibbonQuarterBasSliceDiffDto } from './IntelRankingRibbonQuarterBasSliceDiffDto';
import {
    IntelRankingRibbonQuarterBasSliceDiffDtoFromJSON,
    IntelRankingRibbonQuarterBasSliceDiffDtoFromJSONTyped,
    IntelRankingRibbonQuarterBasSliceDiffDtoToJSON,
} from './IntelRankingRibbonQuarterBasSliceDiffDto';

/**
 * 
 * @export
 * @interface IntelRankingRibbonQuarterBarSliceDto
 */
export interface IntelRankingRibbonQuarterBarSliceDto {
    /**
     * 
     * @type {string}
     * @memberof IntelRankingRibbonQuarterBarSliceDto
     */
    sliceName: string;
    /**
     * 
     * @type {number}
     * @memberof IntelRankingRibbonQuarterBarSliceDto
     */
    productivity: number;
    /**
     * 
     * @type {number}
     * @memberof IntelRankingRibbonQuarterBarSliceDto
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof IntelRankingRibbonQuarterBarSliceDto
     */
    quartile: number;
    /**
     * 
     * @type {IntelRankingRibbonQuarterBasSliceDiffDto}
     * @memberof IntelRankingRibbonQuarterBarSliceDto
     */
    diff?: IntelRankingRibbonQuarterBasSliceDiffDto | null;
}

/**
 * Check if a given object implements the IntelRankingRibbonQuarterBarSliceDto interface.
 */
export function instanceOfIntelRankingRibbonQuarterBarSliceDto(value: object): value is IntelRankingRibbonQuarterBarSliceDto {
    if (!('sliceName' in value) || value['sliceName'] === undefined) return false;
    if (!('productivity' in value) || value['productivity'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    if (!('quartile' in value) || value['quartile'] === undefined) return false;
    return true;
}

export function IntelRankingRibbonQuarterBarSliceDtoFromJSON(json: any): IntelRankingRibbonQuarterBarSliceDto {
    return IntelRankingRibbonQuarterBarSliceDtoFromJSONTyped(json, false);
}

export function IntelRankingRibbonQuarterBarSliceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelRankingRibbonQuarterBarSliceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sliceName': json['SliceName'],
        'productivity': json['Productivity'],
        'rank': json['Rank'],
        'quartile': json['Quartile'],
        'diff': json['Diff'] == null ? undefined : IntelRankingRibbonQuarterBasSliceDiffDtoFromJSON(json['Diff']),
    };
}

export function IntelRankingRibbonQuarterBarSliceDtoToJSON(value?: IntelRankingRibbonQuarterBarSliceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'SliceName': value['sliceName'],
        'Productivity': value['productivity'],
        'Rank': value['rank'],
        'Quartile': value['quartile'],
        'Diff': IntelRankingRibbonQuarterBasSliceDiffDtoToJSON(value['diff']),
    };
}

