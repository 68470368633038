import { useIsFetching } from "@tanstack/react-query";
import { WellStatusType } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { DatePicker } from "atoms/DatePicker";
import { AutoComplete, AutoCompleteDropDown, Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { getTitleRealTimeIndicator } from "components/DetailsTopBar/utils";
import { AllFilters } from "components/Filters/AllFilters";
import { FilterTags } from "components/Filters/FilterTags";
import { MapControlHeader } from "components/MapControlHeader";
import { useWellsMapViewContext } from "components/MapView/useWellsMapView";
import { PDComponent } from "components/PDComponents";
import {
  RealTimeDataEnum,
  RealTimeIndicator,
} from "components/RealTimeIndicator";
import type { LinkedSelectorRef } from "components/RigsHeader/LinkedSelectors";
import { LinkedSelectors } from "components/RigsHeader/LinkedSelectors";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { useFilterTags } from "hooks/filters/useFilterTags";
import {
  AttributeStore,
  useLinkedWellAttributes,
} from "hooks/filters/useLinkedAttributes";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useCurrentUser } from "hooks/user/useCurrentUser";
import {
  FilterTagsContainer,
  HeaderContainer,
  PaddedSpace,
  RightContainer,
  StyledButton,
  StyledCol,
  StyledTitle,
} from "pages/AllWells/components/AllWellsHeader/style";
import { useFilteredAllWellsContext } from "pages/AllWells/useFilteredAllWells";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import type { IFilterOptions } from "reducers/rigsCommonReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { Col, Popover, Row, Space, Tooltip } from "utils/componentLibrary";
import { highPrivilegeRoles, rangeAll } from "utils/helper";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

const AllWellsHeader: FC<{
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>;
  wellFilters: IFilterOptions | null;
}> = ({ setHeaderHeight, wellFilters }) => {
  const { isEvergreen } = useDashboardType();
  const dispatch = useAppDispatch();
  const { data: userData } = useCurrentUser();
  const isInDemoMode = userData?.isInDemoMode ?? false;

  const storeName = isEvergreen
    ? AttributeStore.evergreenWells
    : AttributeStore.allWells;
  const trackingTitle = isEvergreen ? "Evergreen Wells" : "All Wells";

  const statusState = useAppSelector((state) => state[storeName].status);
  const searchState = useAppSelector((state) => state[storeName].search);
  const startDateState = useAppSelector((state) => state[storeName].startDate);
  const endDateState = useAppSelector((state) => state[storeName].endDate);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setHeaderHeight(entries[0].contentRect.height);
    });
    containerRef.current && observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [setHeaderHeight]);

  const { handleOnCircleAreaPress, handleOnPolygonAreaPress } =
    useWellsMapViewContext();
  const [wellSearchPopup, setWellSearchPopup] = useState(false);

  const [realTimeDataState, setRealtimeData] = useStateQuery<RealTimeDataEnum>(
    URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    RealTimeDataEnum.ACTIVE,
  );
  useEffect(() => {
    if (realTimeDataState !== RealTimeDataEnum.DISABLED)
      setRealtimeData(RealTimeDataEnum.ACTIVE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [quickSearchValue, setQuickSearchValue] = useState<string>(searchState);
  const [quickSearchOptions, setQuickSearchOptions] = useState<
    { value: string }[]
  >([]);

  const [showStatusPopup, setShowStatusPopup] = useState(false);

  const updateStatuses = (f: WellStatusType[]) => {
    setShowStatusPopup(false);
    Track.interact(`${trackingTitle} - State`, {
      Action: "Update",
      "Current State": f,
    });
    dispatch({
      type: isEvergreen
        ? "EVERGREEN_WELLS_SET_STATUSES"
        : "ALL_WELLS_SET_STATUSES",
      payload: {
        status: f,
      },
    });
    return true;
  };

  const updatePeriod = useCallback(
    (period: RangeType) => {
      Track.interact(`${trackingTitle} - Time Range`, {
        "Start Date": period.startDate?.toLocaleDateString?.() ?? "",
        "End Date": period.endDate?.toLocaleDateString?.() ?? "",
      });
      dispatch({
        type: isEvergreen
          ? "EVERGREEN_WELLS_SET_TIMERANGE"
          : "ALL_WELLS_SET_TIMERANGE",
        payload: {
          startDate: period.startDate || null,
          endDate: period.endDate || null,
        },
      });
    },
    [trackingTitle, dispatch, isEvergreen],
  );

  const updateSearch = (e: string) => {
    Track.interact(`${trackingTitle} - Search`, {
      "Searched Well": e,
    });
    dispatch({
      type: isEvergreen ? "EVERGREEN_WELLS_SET_SEARCH" : "ALL_WELLS_SET_SEARCH",
      payload: {
        search: e,
      },
    });
    return true;
  };

  const setFilters = useCallback(
    (localFilters: IFilterOptions | null) => {
      dispatch({
        type: isEvergreen
          ? "EVERGREEN_WELLS_SET_FILTERS"
          : "ALL_WELLS_SET_FILTERS",
        payload: localFilters,
      });
    },
    [dispatch, isEvergreen],
  );
  const ref = useRef<LinkedSelectorRef>(null);

  useEffect(() => {
    const handleScroll = () => {
      setWellSearchPopup(false);
      ref.current?.closePopups();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { updateFilters, selectedValues, options } = useLinkedWellAttributes({
    storeName,
    closePopups: ref.current?.closePopups,
  });

  const tags = useFilterTags({
    updateFilters,
    filters: wellFilters,
    setFilters,
    selectedValues,
    options,
    statusState,
    setStatusState: (status: WellStatusType[]) => {
      dispatch({
        type: isEvergreen
          ? "EVERGREEN_WELLS_SET_STATUSES"
          : "ALL_WELLS_SET_STATUSES",
        payload: { status },
      });
    },
    attributeStoreName: storeName,
  });

  useEffect(() => {
    setQuickSearchOptions(
      (options.wells ?? [])
        .map((well) => ({
          value: well.name,
        }))
        .filter((x) => {
          if (quickSearchValue) {
            return x.value
              .toLowerCase()
              .includes(quickSearchValue.toLowerCase());
          }
          return true;
        }),
    );
  }, [options.wells, quickSearchValue]);

  const { atomThemeVariant, isDark } = useCustomTheme();
  const { visibleWellsList } = useFilteredAllWellsContext();

  const shouldDisplayMapControls = useMemo(
    () => handleOnCircleAreaPress && handleOnPolygonAreaPress,
    [handleOnCircleAreaPress, handleOnPolygonAreaPress],
  );

  const isLoading = useIsFetching({
    predicate(query) {
      return (
        [
          PDQueryType.WELL_SUMMARIES,
          PDQueryType.LINKED_FILTERS,
        ] as PDQueryType[]
      ).includes(
        (
          query.queryKey[0] as {
            type: PDQueryType;
          }
        ).type,
      );
    },
  });

  const wellsText = (() => {
    if (isLoading || !visibleWellsList) {
      return "Loading Wells";
    }

    return `${isEvergreen ? "EverGreen" : ""} Wells ${(
      visibleWellsList ?? []
    ).length.toLocaleString("en-US", {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })}`;
  })();
  const disabledFilters = useMemo(
    () =>
      [
        "holeSection",
        "directionalInterval",
        "holeSize",
        "wellStatus",
        "phases",
      ] as (keyof IFilterOptions)[],
    [],
  );

  return (
    <HeaderContainer
      justify="space-between"
      align="middle"
      gutter={8}
      wrap={false}
      ref={containerRef}
    >
      <StyledCol>
        <StyledTitle level={2} variant={atomThemeVariant} weight={500}>
          {wellsText}
        </StyledTitle>
        <AutoComplete
          dropdownRender={(menu) => (
            <AutoCompleteDropDown>{menu}</AutoCompleteDropDown>
          )}
          open={wellSearchPopup}
          onDropdownVisibleChange={(e) => setWellSearchPopup(e)}
          allowClear
          options={quickSearchOptions}
          placeholder="Search by Well"
          style={{ width: "300px" }}
          onChange={(e) => setQuickSearchValue(e as string)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateSearch(quickSearchValue);
            }
          }}
          onClear={() => {
            updateFilters("wells")(null);
          }}
          onSelect={(e) => {
            updateSearch(e as string);
          }}
          value={quickSearchValue}
          popupClassName="all-wells-autocomplete"
        />
        <StyledButton
          size="large"
          $isDark={isDark}
          type={isDark ? "default" : "primary"}
          icon={<PDComponent.SvgIcon name="search" />}
          onClick={() => updateSearch(quickSearchValue)}
        />
      </StyledCol>
      <Col>
        <Row align="middle" gutter={8}>
          <Space>
            <RightContainer wrap>
              {/* Well Search Controls */}
              <PaddedSpace direction="horizontal" size={4}>
                <FilterTagsContainer>
                  <FilterTags tags={tags} />
                </FilterTagsContainer>
              </PaddedSpace>

              <PaddedSpace direction="horizontal" size={4}>
                <Title level={3} variant="faded">
                  Filter by
                </Title>

                <AllFilters
                  filters={wellFilters}
                  setFilters={setFilters}
                  scrollElement={document}
                  disabledFilters={disabledFilters}
                  attributeStoreName={storeName}
                />

                <LinkedSelectors storeName={storeName} />

                {/* Time Range controls */}
                <DatePicker
                  allowAllDates
                  onApply={updatePeriod}
                  defaultDate={rangeAll}
                  selection={{
                    startDate: startDateState,
                    endDate: endDateState,
                  }}
                  title="Time Range"
                />

                {/* Well State controls */}
                <Tooltip title="State">
                  <Popover
                    content={
                      <PDComponent.Pickoff
                        options={Object.values(WellStatusType).map((e) => ({
                          id: e,
                          name: e,
                        }))}
                        values={statusState}
                        onChange={(e) => updateStatuses(e)}
                      />
                    }
                    trigger="click"
                    placement="bottom"
                    open={showStatusPopup}
                    onOpenChange={(e) => setShowStatusPopup(e)}
                    destroyTooltipOnHide
                  >
                    <Button
                      size="large"
                      icon={<PDComponent.SvgIcon name="checkmarkOutline" />}
                      type={
                        statusState.length !==
                        Object.keys(WellStatusType).length
                          ? "primary"
                          : "default"
                      }
                      ghost={
                        statusState.length !==
                        Object.keys(WellStatusType).length
                      }
                      $engaged={showStatusPopup}
                    />
                  </Popover>
                </Tooltip>
              </PaddedSpace>

              <PaddedSpace direction="horizontal" size={4}>
                {shouldDisplayMapControls ? (
                  <Row align="middle" gutter={8}>
                    <Col>
                      <Title level={3} variant="faded">
                        Map Area
                      </Title>
                    </Col>
                    <Col>
                      <MapControlHeader
                        trackingTitle={trackingTitle}
                        isDark={isDark}
                      />
                    </Col>
                  </Row>
                ) : null}

                <PDComponent.VerticalDivider />
                <Tooltip title={getTitleRealTimeIndicator(realTimeDataState)}>
                  <Button
                    size="large"
                    onClick={() => {
                      Track.interact(`${trackingTitle} - Real Time`, {
                        State:
                          realTimeDataState === RealTimeDataEnum.ACTIVE
                            ? "Disabled"
                            : "Active",
                      });
                      setRealtimeData(
                        realTimeDataState === RealTimeDataEnum.ACTIVE
                          ? RealTimeDataEnum.DISABLED
                          : RealTimeDataEnum.ACTIVE,
                      );
                    }}
                    icon={
                      <RealTimeIndicator
                        realTimeDataState={realTimeDataState}
                      />
                    }
                  />
                </Tooltip>
                <PDComponent.VerticalDivider />
                {userData?.role &&
                highPrivilegeRoles.includes(userData?.role) &&
                !isEvergreen &&
                !isInDemoMode ? (
                  <Tooltip title="New Well">
                    <Link to="/well/new">
                      <Button
                        size="large"
                        type={isDark ? "default" : "primary"}
                        onClick={() => Track.interact("Navigate - New Well")}
                      >
                        New Well
                      </Button>
                    </Link>
                  </Tooltip>
                ) : null}
              </PaddedSpace>
            </RightContainer>
          </Space>
        </Row>
      </Col>
    </HeaderContainer>
  );
};

export default AllWellsHeader;
