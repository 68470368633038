import { Loader } from "components/Loader";
import {
  Header,
  ScoreBreakoutModalTab,
} from "pages/RigLeaderboard/components/ScoreBreakout/Header";
import { ScorecardBreakout } from "pages/RigLeaderboard/components/ScoreBreakout/ScorecardBreakout";
import * as Styled from "pages/RigLeaderboard/components/ScoreBreakout/styles";
import { SummarizedComments } from "pages/RigLeaderboard/components/ScoreBreakout/SummarizedComments";
import type { IPartialRig } from "pages/RigLeaderboard/components/ScoreBreakout/useScoreBreakoutModal";
import {
  MODAL_MAX_HEIGHT,
  MODAL_MIN_HEIGHT,
  MODAL_MIN_WIDTH,
} from "pages/RigLeaderboard/components/utils";
import React, { Suspense, useState } from "react";
import Modal from "react-modal";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

interface ModalProps {
  onCancel: (
    event?:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>,
  ) => void;
  selectedRig: IPartialRig;
  isVisible: boolean;
  justComment?: boolean;
}

const customStyles = {
  overlay: {
    zIndex: zIndexLayer.mars,
    backgroundColor: "rgba(32,43,48,0.6)",
  },
  content: {
    top: "10vh",
    left: "50%",
    right: "auto",
    borderRadius: 12,
    bottom: "auto",
    padding: "0px!important",
    marginRight: "-50%",
    transform: "translate(-50%, 0%)",
    width: `${MODAL_MIN_WIDTH}px`,
    minWidth: `${MODAL_MIN_WIDTH}px`,
    minHeight: `${MODAL_MIN_HEIGHT}px`,
    maxHeight: `${MODAL_MAX_HEIGHT}px`,
    overflow: "hidden",
    border: "none",
  },
};

export const ScoreBreakoutModal: React.FC<ModalProps> = ({
  isVisible,
  onCancel,
  selectedRig,
  justComment,
}) => {
  const { themeStyle } = useCustomTheme();
  const [selectedView, setSelectedView] = useState(
    justComment
      ? ScoreBreakoutModalTab.Comments
      : ScoreBreakoutModalTab.Breakout,
  );

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onCancel}
      style={{
        content: {
          ...customStyles.content,
          backgroundColor: themeStyle.colors.primary_bg,
        },
        overlay: customStyles.overlay,
      }}
      ariaHideApp={false}
    >
      <Styled.StoreBreakoutContainer>
        <Header
          selectedRig={selectedRig}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          onCancel={onCancel}
          justComment={justComment}
        />
        <Suspense
          fallback={
            <Styled.LoaderContainer>
              <Loader centered />
            </Styled.LoaderContainer>
          }
        >
          {selectedView === ScoreBreakoutModalTab.Breakout ? (
            <ScorecardBreakout selectedRig={selectedRig} />
          ) : (
            <SummarizedComments
              selectedRig={selectedRig}
              summaryType={selectedRig.summaryType}
              onTaskFinished={onCancel}
            />
          )}
        </Suspense>
      </Styled.StoreBreakoutContainer>
    </Modal>
  );
};
