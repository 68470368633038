import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Text } from "@visx/text";
import {
  type IntelRankingRibbonUserLensDto,
  IntelRankingRibbonUserLensesApi,
  TimeUnit,
} from "apis/oag";
import { Title } from "atoms/Typography";
import { StandardTickComponent } from "components/Lenses/common/ChartElements";
import NoData from "components/Lenses/common/NoData";
import {
  Scrollbar,
  type ScrollbarRange,
} from "components/Lenses/common/Scrollbar";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import {
  getAxisFontSize,
  LATERAL_AXIS_TEXT_WIDTH,
  LATERAL_AXIS_WIDTH,
  LATERAL_LABEL_POSITION,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import { useIntelRankingRibbonFacts } from "hooks/facts/useIntelRankingRibbonFacts";
import { groupBy } from "lodash";
import { Quartiles } from "pages/IntelDashboard/components/common/Quartiles";
import {
  CardHeader,
  CardTitle,
} from "pages/IntelDashboard/components/IntelScatterPlot/style";
import { IntelLegendColorsContext } from "pages/IntelDashboard/useIntelLegendColors";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Modal from "react-modal";
import { useResizeDetector } from "react-resize-detector";
import { selectIntelFiltersWithMap } from "reducers/intelDashboardReducer";
import { useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { Card, Col, Divider, Row, Space, Switch } from "utils/componentLibrary";
import { useMetresPerTimeUnitUom } from "utils/format";
import { RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import type { IntelRankingRibbonVm } from "./mappers";
import {
  IntelRankingRibbonByProductivitySelector,
  IntelRankingRibbonByQuartileSelector,
  PADDING_SLICE_NAME,
} from "./mappers";
import { RibbonLinks } from "./RibbonLinks";
import {
  CardInner,
  Circle,
  ContainerDiv,
  modalStyles,
  RankSpan,
  StyledExpandButton,
  TooltipContainer,
} from "./style";
import type { TTooltip } from "./types";
import {
  BOTTOM_AXIS_HEIGHT,
  BOTTOM_AXIS_WITH_LABEL_HEIGHT,
  getColorByDiff,
  getIconByDiff,
  LEFT_AXIS_WIDTH,
  SCROLLBAR_HEIGHT,
  SCROLLBAR_PADDING,
  ZOOM_RATE_BREAKPOINT_LARGE,
  ZOOM_RATE_BREAKPOINT_MID,
  ZOOM_RATE_BREAKPOINT_SMALL,
} from "./utils";
const RIGHT_AXIS_WIDTH = 40;
const api = new IntelRankingRibbonUserLensesApi(apiConfig);

export const IntelRankingRibbon = ({
  lens,
  isExpanded = false,
  onModalClose,
}: {
  lens: IntelRankingRibbonUserLensDto;
  isExpanded?: boolean;
  onModalClose?: () => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getColor } = useContext(IntelLegendColorsContext);
  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: outsideRef,
  } = useResizeDetector();
  const { chartWidth, chartHeight } = useMemo(
    () => ({
      chartHeight: getSVGNormalizedValue(chartHeightHook),
      chartWidth: getSVGNormalizedValue(chartWidthHook),
    }),
    [chartHeightHook, chartWidthHook],
  );

  const groupingType = useAppSelector(
    (state) => state.intelDashboard.groupingType,
  );

  const width = useMemo(() => chartWidth, [chartWidth]);
  const height = useMemo(() => chartHeight, [chartHeight]);
  const containerRef = useRef<HTMLDivElement>(null);
  const plotHeight = useMemo(
    () =>
      getSVGNormalizedValue(
        height - BOTTOM_AXIS_WITH_LABEL_HEIGHT - BOTTOM_AXIS_HEIGHT,
      ),
    [height],
  );

  const plotWidth = useMemo(
    () => getSVGNormalizedValue(width - LEFT_AXIS_WIDTH - RIGHT_AXIS_WIDTH),
    [width],
  );

  const intelFilters = useAppSelector(selectIntelFiltersWithMap);

  const queryClient = useQueryClient();

  const updateLensMutation = useMutation({
    mutationFn: (lens: IntelRankingRibbonUserLensDto) => {
      return api.apiIntelRankingRibbonUserLensesIdPut({
        id: lens.id,
        intelRankingRibbonUserLensDto: lens,
      });
    },
    onSettled: (updatedLens) => {
      queryClient.setQueryData(
        [{ uid: RequestUID.intelLenses }],
        (data: IntelRankingRibbonUserLensDto[]) => {
          return data.map((l) => {
            if (l.id === lens.id) {
              return updatedLens;
            }
            return l;
          });
        },
      );
    },
  });

  const handleQuartileChecked = (showQuartile: boolean) => {
    Track.interact(`Intel Dashboard - Ranking Ribbon - Quartile Checkbox`, {
      checked: showQuartile,
    });

    queryClient.setQueryData(
      [{ uid: RequestUID.intelLenses }],
      (data: IntelRankingRibbonUserLensDto[]) => {
        return data.map((l) => {
          if (l.id === lens.id) {
            return {
              ...l,
              showQuartile,
            };
          }
          return l;
        });
      },
    );
    updateLensMutation.mutate({
      ...lens,
      showQuartile,
    });
  };

  const { data } = useIntelRankingRibbonFacts(
    lens.id,
    {
      id: lens.id,
      // comes from state
      wellIntelQueryDto: {
        ...intelFilters,
        grouping: groupingType,
      },
    },
    {
      select:
        lens.showQuartile && lens.showQuartile !== undefined
          ? IntelRankingRibbonByQuartileSelector
          : IntelRankingRibbonByProductivitySelector,
    },
  );

  // each bar is going to have height of value
  const names = useMemo(
    () => [...new Set(data?.map((d) => d.name).filter((name) => name))],
    [data],
  );

  const dataSeriesMapper = useMemo(() => {
    return names.reduce(
      (acc, name) => {
        const dataForSeries = data?.filter((d) => d.name === name) ?? [];
        return {
          ...acc,
          [name]: dataForSeries,
        };
      },
      {} as Record<string, IntelRankingRibbonVm[]>,
    );
  }, [data, names]);

  const quarters = useMemo(
    () => [...new Set(data?.map((d) => d.quarter))],
    [data],
  );

  const needsScrollBar = useMemo(
    () => quarters.length > ZOOM_RATE_BREAKPOINT_SMALL,
    [quarters],
  );

  const scrollbarWidth = getSVGNormalizedValue(
    plotWidth - SCROLLBAR_PADDING * 2,
  );

  const zoomRate = useMemo(() => {
    if (
      quarters.length > ZOOM_RATE_BREAKPOINT_SMALL &&
      quarters.length <= ZOOM_RATE_BREAKPOINT_MID
    ) {
      return 1.5;
    }
    if (
      quarters.length > ZOOM_RATE_BREAKPOINT_MID &&
      quarters.length <= ZOOM_RATE_BREAKPOINT_LARGE
    ) {
      return 2;
    }
    if (quarters.length > ZOOM_RATE_BREAKPOINT_LARGE) {
      return 2.5;
    }
    return 1;
  }, [quarters]);

  const [scrollbarRange, setScrollbarRange] = useState<ScrollbarRange>({
    startX: 1 - 1 / zoomRate,
    endX: 1,
  });

  useEffect(() => {
    setScrollbarRange({ startX: 1 - 1 / zoomRate, endX: 1 });
  }, [zoomRate]);

  const xAxis = useMemo(() => {
    const chartSize = plotWidth * zoomRate;

    const startX = chartSize * scrollbarRange.startX;

    return scaleBand<string>({
      domain: quarters,
      range: [-startX, chartSize - startX],
      paddingInner: 0.6,
      paddingOuter: 0.2,
    });
  }, [quarters, plotWidth, scrollbarRange, zoomRate]);

  const scrollbarScale = useMemo(
    () => scaleLinear<number>({ domain: [0, 1], range: [0, scrollbarWidth] }),
    [scrollbarWidth],
  );

  const yAxisDisplayScaleQuartile = useMemo(() => {
    return scaleLinear({
      domain: [100, 0],
      range: [plotHeight, 0],
    });
  }, [plotHeight]);
  const yAxis = useMemo(() => {
    if (lens.showQuartile)
      return scaleLinear({
        domain: [1, 0],
        range: [plotHeight, 0],
      });
    return scaleLinear({
      domain: [
        Math.max(
          ...Object.values(groupBy(data, (d) => d.quarter)).map((d) =>
            d.reduce((acc, curr) => acc + curr.productivity, 0),
          ),
        ),
        0,
      ],
      range: [plotHeight, 0],
    });
  }, [data, lens.showQuartile, plotHeight]);

  // currentValue is all the previous values plus the current one
  const getCurrentValue = useCallback(
    (d: IntelRankingRibbonVm, data: IntelRankingRibbonVm[]) => {
      const previous = data.filter(
        (p) => p.quarter === d.quarter && p.rank < d.rank,
      );
      if (previous.length === 0) {
        return yAxis(0);
      }

      return previous.reduce((acc, curr) => {
        return acc + yAxis(curr.productivity);
      }, 0);
    },
    [yAxis],
  );

  const {
    themeStyle: { colors: themeColors, intel: intelColors },
  } = useCustomTheme();
  const axisFontSize = useMemo(() => getAxisFontSize(false), []);

  const uom = useMetresPerTimeUnitUom(TimeUnit.Day);

  const { verticalAxisTicksCount, yDisplayOnlyScale, formatTickDecimals } =
    useYAxisDisplayScale({
      originalScale: yAxis,
      uom,
      tickContainerHeight: plotHeight,
    });

  const { showTooltip, hideTooltip, tooltipElement } =
    useChartTooltip<TTooltip>({
      containerRef,
      renderContent: ({ tooltipData }) => {
        if (!tooltipData) return null;
        return (
          <TooltipContainer>
            {(Object.keys(tooltipData) as Array<keyof TTooltip>).map((key) =>
              key === "Productivity Change" ? (
                <Row
                  key={key}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col>{key}</Col>
                  <Col>
                    <RankSpan color={tooltipData[key]?.color ?? ""}>
                      {tooltipData[key]?.value}
                    </RankSpan>
                  </Col>
                </Row>
              ) : (
                <Row
                  key={key}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {key === "Legend" ? (
                    <>
                      <Divider
                        style={{
                          width: "100%",
                          borderTop: `1px solid #828C911F`,
                          margin: "8px 0",
                        }}
                      />
                      <Col>{key}</Col>
                      <Col>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <Circle color={tooltipData[key].color} />
                              </td>
                              <td>{tooltipData[key].value}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col>{key}</Col>
                      <Col>{tooltipData[key]}</Col>
                    </>
                  )}
                </Row>
              ),
            )}
          </TooltipContainer>
        );
      },
    });

  const handlePointerMove = useCallback(
    (
      event: React.MouseEvent<SVGPathElement, MouseEvent>,
      currentPoint: IntelRankingRibbonVm,
    ) => {
      if (!currentPoint) return;
      if (currentPoint.name === PADDING_SLICE_NAME) return;
      const { left, top } = containerRef.current?.getBoundingClientRect() ?? {
        left: 0,
        top: 0,
      };
      const x = ("clientX" in event ? event.clientX : 0) - left;
      const y = ("clientY" in event ? event.clientY : 0) - top;

      const currentProductivity = uom.display(currentPoint.productivity, {
        fractionDigits: 0,
      });

      const productivityChange = currentPoint.diff?.productivityDiff ?? 0;

      const productivityChangeDisplay = {
        value: currentPoint.diff?.productivityDiff
          ? `${uom.display(productivityChange, {
              fractionDigits: 0,
            })} (${productivityChange > 0 ? "+" : "-"}${(
              (currentPoint.diff?.productivityDiffPct ?? 0) * 100
            ).toFixed(0)}%)`
          : "- -",
        color: getColorByDiff(productivityChange),
      };

      const rankIcon = getIconByDiff(currentPoint.diff?.rankDiff);

      const rankChange = (
        <RankSpan
          color={getColorByDiff((currentPoint.diff?.rankDiff ?? 0) * -1)}
        >
          {rankIcon ? <PDComponent.SvgIcon name={rankIcon} /> : null}

          {currentPoint.diff?.rankDiff
            ? `${Math.abs(currentPoint.diff?.rankDiff)}`
            : "- -"}
        </RankSpan>
      );

      const legend = {
        value: currentPoint.name,
        color: getColor({
          key: currentPoint.name,
        }),
      };
      const quartileIcon = getIconByDiff(currentPoint.diff?.quartileDiff);
      const quartileChange = (
        <RankSpan
          color={getColorByDiff((currentPoint.diff?.quartileDiff ?? 0) * -1)}
        >
          {quartileIcon ? <PDComponent.SvgIcon name={quartileIcon} /> : null}

          {currentPoint.diff?.quartileDiff
            ? `${Math.abs(currentPoint.diff?.quartileDiff)}`
            : "- -"}
        </RankSpan>
      );

      showTooltip({
        tooltipLeft: x,
        tooltipTop: y,
        tooltipData: lens.showQuartile
          ? {
              Quartile: currentPoint.quartile.toString(),
              "Quartile Change": quartileChange,
              Rank: currentPoint.rank,
              "Rank Change": rankChange,
              Legend: legend,
            }
          : {
              Productivity: currentProductivity,
              "Productivity Change": productivityChangeDisplay,
              Rank: currentPoint.rank,
              "Rank Change": rankChange,
              Legend: legend,
            },
      });
    },
    [uom, getColor, showTooltip, lens.showQuartile],
  );
  const highlightedIds = useAppSelector(
    (state) => state.intelDashboard.highlightedIds,
  );

  const { themeStyle, isDark } = useCustomTheme();
  if (!data) return null;

  return (
    <CardInner ref={outsideRef}>
      <CardHeader>
        <CardTitle>Ranking Ribbon Chart</CardTitle>
        <Space>
          <Switch
            size="small"
            checked={lens.showQuartile}
            onChange={() => handleQuartileChecked(!lens.showQuartile)}
          />

          <Title level={3}>Quartile</Title>
          <StyledExpandButton
            type="ghost"
            icon={
              isExpanded ? (
                <PDComponent.SvgIcon name="fleetPerformanceMinimizeView" />
              ) : (
                <PDComponent.SvgIcon
                  name="fleetPerformanceExpandView"
                  height={14}
                />
              )
            }
            onClick={() => {
              Track.interact("Intel Dashboard - Ranking Ribbon - Expand View");
              if (!isExpanded) {
                setIsModalOpen(true);
              } else {
                onModalClose?.();
              }
            }}
          />
        </Space>
      </CardHeader>
      <ContainerDiv ref={containerRef} $isExpanded={isExpanded}>
        {names.length === 0 ? (
          <NoData isStatic />
        ) : width <= 0 || height <= 0 ? null : (
          <svg width={width} height={height}>
            {!lens.showQuartile ? (
              <GridRows
                scale={yDisplayOnlyScale}
                width={
                  xAxis.range()[1] - xAxis.paddingOuter() * xAxis.step() * 2
                }
                height={plotHeight}
                numTicks={verticalAxisTicksCount / 2}
                stroke={intelColors.grid_lines}
                strokeWidth={1}
                strokeOpacity={1}
                left={LEFT_AXIS_WIDTH + xAxis.paddingOuter() * xAxis.step()}
                top={BOTTOM_AXIS_HEIGHT}
              />
            ) : null}
            <Group top={BOTTOM_AXIS_HEIGHT} left={10}>
              {lens.showQuartile ? (
                <Quartiles
                  plotHeight={plotHeight}
                  plotWidth={plotWidth + RIGHT_AXIS_WIDTH - 10}
                  yScale={yAxis}
                  isVerticalWriting
                  quartileThresholds={[0.75, 0.5, 0.25, 0]}
                />
              ) : null}
            </Group>
            <clipPath id={`clip-${plotWidth}-${plotHeight}`}>
              <rect x={0} y={0} width={plotWidth} height={"100%"} fill="red" />
            </clipPath>

            <Group
              top={BOTTOM_AXIS_HEIGHT}
              left={LEFT_AXIS_WIDTH}
              clipPath={`url(#clip-${plotWidth}-${plotHeight})`}
            >
              {/* create rectangles for each of the values for the series */}
              {names.map((name) => {
                if (!name) return null;

                return (
                  <Group key={name}>
                    {dataSeriesMapper[name].map((d, i) => {
                      const height = yAxis(d.productivity);
                      const y = getCurrentValue(d, data);

                      if (height <= 0) return null;
                      const isHighlighted =
                        highlightedIds && highlightedIds?.size > 0
                          ? highlightedIds.has(d.name)
                          : true;

                      const getOpacity = () => {
                        if (d.name === PADDING_SLICE_NAME) {
                          return 0.8;
                        }
                        if (!isHighlighted && lens.showQuartile) {
                          return 1;
                        }
                        if (!isHighlighted) {
                          return 0.6;
                        }
                        return 1;
                      };

                      const getRectFill = () => {
                        if (name === PADDING_SLICE_NAME)
                          return "url(#diagonalHatch)";
                        if (!isHighlighted && lens.showQuartile) {
                          switch (d.quartile) {
                            case 1:
                              return isDark ? "#1a662e" : "#A7DCB7";
                            case 2:
                              return isDark ? "#386267" : "#BEDDE2";
                            case 3:
                              return isDark ? "#887c50" : "#F9F1CF";
                            case 4:
                              return isDark ? "#735652" : "#E9D5D1";
                            default:
                              return colors.necron_compound;
                          }
                        }

                        return getColor({
                          key: d.name,
                        });
                      };

                      const getBorder = () => {
                        if (
                          !isHighlighted &&
                          lens.showQuartile &&
                          name !== PADDING_SLICE_NAME
                        ) {
                          switch (d.quartile) {
                            case 1:
                              return isDark
                                ? "#2f7050"
                                : "rgba(7, 160, 50, 0.50)";
                            case 2:
                              return isDark
                                ? "#38686e"
                                : "rgba(71, 160, 171, 0.50)";
                            case 3:
                              return isDark ? "#9b8c59" : "#F2D87A";
                            case 4:
                              return isDark
                                ? "#7b5c56"
                                : "rgba(196, 135, 125, 0.50)";
                          }
                        }
                        return undefined;
                      };
                      // if no next in the next quarter you don t connect them
                      return (
                        <Group key={`${d.name}-${d.quarter}-${d.rank}`}>
                          <pattern
                            id="diagonalHatch"
                            patternUnits="userSpaceOnUse"
                            width="8"
                            height="8"
                          >
                            <path
                              d="M-2,2 l4,-4 M0,8 l8,-8 M6,10 l4,-4"
                              style={{
                                stroke: colors.necron_compound,
                                strokeWidth: 1,
                              }}
                            />
                          </pattern>
                          <rect
                            x={xAxis(d.quarter) ?? 0}
                            y={y}
                            stroke={getBorder()}
                            onMouseLeave={hideTooltip}
                            fill={getRectFill()}
                            onMouseMove={(e) => {
                              handlePointerMove(e, d);
                            }}
                            opacity={getOpacity()}
                            width={xAxis.bandwidth()}
                            height={height}
                          />

                          {name === PADDING_SLICE_NAME ? (
                            <rect
                              x={xAxis(d.quarter) ?? 0}
                              y={y}
                              onMouseLeave={hideTooltip}
                              fill={getColor({
                                key: d.name,
                              })}
                              opacity={0.8}
                              width={xAxis.bandwidth()}
                              height={height}
                            />
                          ) : null}

                          {d.name === PADDING_SLICE_NAME ? null : (
                            <RibbonLinks
                              d={d}
                              hideTooltip={hideTooltip}
                              handlePointerMove={handlePointerMove}
                              xAxis={xAxis}
                              yAxis={yAxis}
                              i={i}
                              dataForSeries={dataSeriesMapper[name]}
                              getCurrentValue={getCurrentValue}
                              data={data}
                            />
                          )}
                        </Group>
                      );
                    })}
                  </Group>
                );
              })}
            </Group>
            <Group
              clipPath={`url(#clip-${plotWidth}-${plotHeight})`}
              left={LEFT_AXIS_WIDTH}
            >
              <AxisBottom
                hideAxisLine
                hideTicks
                label="Quarterly Time Trend"
                labelProps={{
                  fill: intelColors.typography,
                  fontSize: axisFontSize,
                  textAnchor: "middle",
                }}
                numTicks={quarters.length}
                scale={xAxis}
                top={plotHeight + BOTTOM_AXIS_HEIGHT}
                tickComponent={(props) => {
                  return (
                    <Text
                      x={props.x}
                      y={props.y - axisFontSize}
                      fontSize={axisFontSize}
                      fill={themeColors.disabled_typography}
                      pointerEvents="none"
                      verticalAnchor="start"
                      textAnchor="middle"
                    >
                      {props.formattedValue}
                    </Text>
                  );
                }}
              />
            </Group>

            {needsScrollBar ? (
              <Group top={BOTTOM_AXIS_HEIGHT - 1}>
                {/* Rect to hide the scrolled chart behind left axis */}
                <rect
                  width={LATERAL_AXIS_WIDTH + LATERAL_AXIS_TEXT_WIDTH}
                  height={plotHeight + BOTTOM_AXIS_HEIGHT + axisFontSize + 1}
                  fill={intelColors.chart_bg}
                />
              </Group>
            ) : null}

            <AxisLeft
              hideTicks
              hideAxisLine
              label={
                lens.showQuartile
                  ? "Quartile Ranking"
                  : `Productivity (${uom.abbr})`
              }
              labelOffset={LATERAL_LABEL_POSITION - LATERAL_AXIS_TEXT_WIDTH}
              scale={
                lens.showQuartile
                  ? yAxisDisplayScaleQuartile
                  : yDisplayOnlyScale
              }
              numTicks={
                lens.showQuartile ? undefined : verticalAxisTicksCount / 2
              }
              tickValues={lens.showQuartile ? [100, 75, 50, 25, 0] : undefined}
              labelProps={{
                fill: intelColors.typography,
                textAnchor: "middle",
                fontSize: axisFontSize,
              }}
              top={BOTTOM_AXIS_HEIGHT}
              left={LATERAL_AXIS_WIDTH - LATERAL_AXIS_TEXT_WIDTH}
              tickComponent={(props) => (
                <StandardTickComponent rendererProps={props}>
                  {(val) => {
                    if (lens.showQuartile) return `${100 - +val}%`;
                    return formatTickDecimals(val);
                  }}
                </StandardTickComponent>
              )}
            />
            {needsScrollBar ? (
              <Group
                top={plotHeight + BOTTOM_AXIS_HEIGHT + SCROLLBAR_PADDING}
                left={SCROLLBAR_PADDING + LATERAL_AXIS_WIDTH}
              >
                <Scrollbar
                  width={scrollbarWidth}
                  height={SCROLLBAR_HEIGHT}
                  scale={scrollbarScale}
                  onScroll={setScrollbarRange}
                  valueSpan={1 / zoomRate}
                />
              </Group>
            ) : null}
            {tooltipElement}
          </svg>
        )}
      </ContainerDiv>
      {isExpanded ? null : (
        <Modal
          isOpen={isModalOpen}
          style={{
            content: {
              ...modalStyles.content,
              backgroundColor: themeStyle.intel.chart_bg,
            },
            overlay: modalStyles.overlay,
          }}
          onRequestClose={() => setIsModalOpen(false)}
        >
          <Card style={{ height: 568 }}>
            <IntelRankingRibbon
              lens={lens}
              isExpanded
              onModalClose={() => setIsModalOpen(false)}
            />
          </Card>
        </Modal>
      )}
    </CardInner>
  );
};
