/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntelRankingRibbonQuarterBarDto } from './IntelRankingRibbonQuarterBarDto';
import {
    IntelRankingRibbonQuarterBarDtoFromJSON,
    IntelRankingRibbonQuarterBarDtoFromJSONTyped,
    IntelRankingRibbonQuarterBarDtoToJSON,
} from './IntelRankingRibbonQuarterBarDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';

/**
 * 
 * @export
 * @interface IntelRankingRibbonFactSetDto
 */
export interface IntelRankingRibbonFactSetDto {
    /**
     * 
     * @type {Array<IntelRankingRibbonQuarterBarDto>}
     * @memberof IntelRankingRibbonFactSetDto
     */
    bars: Array<IntelRankingRibbonQuarterBarDto>;
    /**
     * 
     * @type {ResultDataState}
     * @memberof IntelRankingRibbonFactSetDto
     */
    readonly dataState: ResultDataState;
}



/**
 * Check if a given object implements the IntelRankingRibbonFactSetDto interface.
 */
export function instanceOfIntelRankingRibbonFactSetDto(value: object): value is IntelRankingRibbonFactSetDto {
    if (!('bars' in value) || value['bars'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function IntelRankingRibbonFactSetDtoFromJSON(json: any): IntelRankingRibbonFactSetDto {
    return IntelRankingRibbonFactSetDtoFromJSONTyped(json, false);
}

export function IntelRankingRibbonFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelRankingRibbonFactSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'bars': ((json['Bars'] as Array<any>).map(IntelRankingRibbonQuarterBarDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function IntelRankingRibbonFactSetDtoToJSON(value?: Omit<IntelRankingRibbonFactSetDto, 'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Bars': ((value['bars'] as Array<any>).map(IntelRankingRibbonQuarterBarDtoToJSON)),
    };
}

