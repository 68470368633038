import { SelectorGroupContainer } from "components/GroupSelector/style";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import styled from "styled-components";
import colors from "utils/colors";
import { Button } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const Card = styled.div`
  height: 100%;
  max-height: 685px;
  width: 100%;
  border-radius: 4px;
  background: ${({ theme }) => theme.themeStyle.colors.secondary_chart_accent};
  box-shadow: 0px 4px 6px 0px ${colors.dark_soft_shadow};
  ${SelectorGroupContainer} {
    margin-bottom: 8px;
  }
`;

export const ScatterPlotContainer = styled.div`
  padding: 16px;
`;
export const ChartContainer = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  display: flex;
  position: relative;
  border-radius: 4px;
  background: ${({ theme }) => theme.themeStyle.intel.chart_bg};
  box-shadow: 0px 4px 6px 0px ${colors.dark_soft_shadow};
`;

export const CardTitle = styled.h1`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 16px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TooltipInfoTable = styled.table`
  max-width: 350px;
  tr > td:first-child {
    padding-right: 16px;
  }
  tr > td:last-child {
    ${TooltipHighlightValue} {
      white-space: normal;
      text-align: right;
    }
  }
`;

export const LegendTableRow = styled.tr`
  td {
    padding-top: 5px;
  }
  border-top: 1px solid ${colors.necron_compound_light};
  > td:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

export const LegendIndicator = styled.span<{ color: string }>`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  margin-right: 5px;
  border-radius: 50%;
`;

export const StyledExpandButton = styled(Button)`
  padding: 0;
  width: 24px;
  margin-left: 4px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const modalStyles = {
  overlay: {
    zIndex: zIndexLayer.stratosphere,
    backgroundColor: colors.faded_bg,
  },
  content: {
    border: "none",
    top: "50%",
    left: "50%",
    overflow: "hidden",
    right: "auto",
    height: "85%",
    width: "60%",
    borderRadius: 6,
    bottom: "auto",
    padding: "0px!important",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
