/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntelRankingRibbonQuarterBasSliceDiffDto
 */
export interface IntelRankingRibbonQuarterBasSliceDiffDto {
    /**
     * 
     * @type {number}
     * @memberof IntelRankingRibbonQuarterBasSliceDiffDto
     */
    productivityDiff: number;
    /**
     * 
     * @type {number}
     * @memberof IntelRankingRibbonQuarterBasSliceDiffDto
     */
    productivityDiffPct: number;
    /**
     * 
     * @type {number}
     * @memberof IntelRankingRibbonQuarterBasSliceDiffDto
     */
    rankDiff: number;
    /**
     * 
     * @type {number}
     * @memberof IntelRankingRibbonQuarterBasSliceDiffDto
     */
    quartileDiff: number;
}

/**
 * Check if a given object implements the IntelRankingRibbonQuarterBasSliceDiffDto interface.
 */
export function instanceOfIntelRankingRibbonQuarterBasSliceDiffDto(value: object): value is IntelRankingRibbonQuarterBasSliceDiffDto {
    if (!('productivityDiff' in value) || value['productivityDiff'] === undefined) return false;
    if (!('productivityDiffPct' in value) || value['productivityDiffPct'] === undefined) return false;
    if (!('rankDiff' in value) || value['rankDiff'] === undefined) return false;
    if (!('quartileDiff' in value) || value['quartileDiff'] === undefined) return false;
    return true;
}

export function IntelRankingRibbonQuarterBasSliceDiffDtoFromJSON(json: any): IntelRankingRibbonQuarterBasSliceDiffDto {
    return IntelRankingRibbonQuarterBasSliceDiffDtoFromJSONTyped(json, false);
}

export function IntelRankingRibbonQuarterBasSliceDiffDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelRankingRibbonQuarterBasSliceDiffDto {
    if (json == null) {
        return json;
    }
    return {
        
        'productivityDiff': json['ProductivityDiff'],
        'productivityDiffPct': json['ProductivityDiffPct'],
        'rankDiff': json['RankDiff'],
        'quartileDiff': json['QuartileDiff'],
    };
}

export function IntelRankingRibbonQuarterBasSliceDiffDtoToJSON(value?: IntelRankingRibbonQuarterBasSliceDiffDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ProductivityDiff': value['productivityDiff'],
        'ProductivityDiffPct': value['productivityDiffPct'],
        'RankDiff': value['rankDiff'],
        'QuartileDiff': value['quartileDiff'],
    };
}

