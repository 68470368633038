import { ErrorBoundary } from "@sentry/react";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { MapControlHeader } from "components/MapControlHeader";
import AgnosticMapView from "components/MapView/AgnosticMapView";
import { useWellsMapViewContext } from "components/MapView/useWellsMapView";
import { PDComponent } from "components/PDComponents";
import { WebGLSupportBanner } from "components/WebGLSupportBanner/WebGLSupportBanner";
import { useIntelLinkedFiltersByGroupAndQuarter } from "hooks/intel/useIntelLinkedFilters";
import { useIntelWells } from "hooks/intel/useIntelWells";
import { useFilteredAllWellsContext } from "pages/AllWells/useFilteredAllWells";
import {
  Suspense,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react";
import Modal from "react-modal";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import { Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

import * as Styled from "./style";

export const MapModalButton = () => {
  const customStyles = {
    overlay: {
      zIndex: zIndexLayer.moon,
      backgroundColor: colors.faded_bg,
    },
    content: {
      top: "50%",
      left: "50%",
      overflow: "hidden",
      right: "auto",
      height: "700px",
      width: "82%",
      borderRadius: 6,
      bottom: "auto",
      padding: "0px!important",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
    },
  };

  const { visibleWellsList } = useFilteredAllWellsContext();

  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const { area, basinName, radius, resetAll } = useWellsMapViewContext();
  const hasNoFormOfMapSelection = !(area || basinName || radius);
  const { themeStyle } = useCustomTheme();

  const dispatch = useAppDispatch();

  const { data: intelWells, isFetching: isFetchingIntelWells } =
    useIntelWells();

  const { data: linkedFiltersAvailableWells, isFetching: isFetchingFilters } =
    useIntelLinkedFiltersByGroupAndQuarter();

  const wellNameSet = useMemo(
    () => new Set(linkedFiltersAvailableWells?.wellNames),
    [linkedFiltersAvailableWells?.wellNames],
  );

  const alreadyHasSelectedWells = useAppSelector(
    (state) => state.intelDashboard.mapSelectedWells,
  );

  useEffect(() => {
    if (hasNoFormOfMapSelection) {
      // To make sure we are clearing the whole selected wells list whenever there is no map selection
      dispatch({
        type: "INTEL_DASHBOARD_SET_MAP_SELECTED_WELLS",
        payload: null,
      });
    }
  }, [dispatch, hasNoFormOfMapSelection]);

  const wellsVmEager = useMemo(
    () =>
      intelWells?.wells
        .filter((well) => wellNameSet.has(well.name))
        .map((well, idx) => ({
          ...well,
          id: idx,
        })),
    [intelWells?.wells, wellNameSet],
  );

  const wellsVm = useDeferredValue(wellsVmEager);

  const handleOnWellsMapChange = useCallback(
    (selection: string[] | null) => {
      const selectionSet = new Set(selection);
      const wellNames = wellsVm
        ?.filter((wellVm) => selectionSet?.has(wellVm.name))
        .map((wellVm) => wellVm.name);

      if (!hasNoFormOfMapSelection && !isMapModalOpen) {
        dispatch({
          type: "INTEL_DASHBOARD_SET_MAP_SELECTED_WELLS",
          payload: wellNames ?? [],
        });
      }
    },
    [dispatch, hasNoFormOfMapSelection, isMapModalOpen, wellsVm],
  );

  return (
    <>
      <Button
        size="large"
        disabled={isFetchingIntelWells || isFetchingFilters}
        onClick={() => {
          Track.interact("Intel - Map View");
          setIsMapModalOpen(true);
        }}
        icon={
          <PDComponent.SvgIcon
            name="mapButtonRigs"
            color={area || basinName || radius ? colors.well_color : undefined}
          />
        }
      />
      <Modal
        appElement={document.getElementById("root") as HTMLElement}
        onRequestClose={() => {
          if (!alreadyHasSelectedWells) {
            resetAll();
            dispatch({
              type: "INTEL_DASHBOARD_SET_MAP_SELECTED_WELLS",
              payload: null,
            });
          }
          setIsMapModalOpen(false);
        }}
        style={{
          content: {
            ...customStyles.content,
            backgroundColor: colors.shutter_black,
            display: isMapModalOpen ? "block" : "none",
          },
          overlay: {
            ...customStyles.overlay,
            display: isMapModalOpen ? "block" : "none",
          },
        }}
        // don t unmount the component, to show the previous state inside the modal
        isOpen={true}
      >
        <Suspense fallback={null}>
          <Styled.HeaderMap>
            <Title level={3} variant="faded">
              Map Interaction
            </Title>
            <MapControlHeader trackingTitle={"Intel"} isDark />
            <Tooltip
              title={
                hasNoFormOfMapSelection
                  ? "Not available until a Map Interaction is selected"
                  : ""
              }
            >
              {/* tooltip element does not work if the button is not wrapped in another element */}
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>
                <Styled.ResetButton
                  disabled={hasNoFormOfMapSelection}
                  onClick={() => {
                    dispatch({
                      type: "INTEL_DASHBOARD_SET_MAP_SELECTED_WELLS",
                      payload: null,
                    });
                    setIsMapModalOpen(false);
                    resetAll();
                  }}
                >
                  Reset
                </Styled.ResetButton>
              </>
            </Tooltip>
            <Tooltip
              title={
                hasNoFormOfMapSelection
                  ? "Not available until a Map Interaction is selected"
                  : ""
              }
            >
              {/* tooltip element does not work if the button is not wrapped in another element */}
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <Styled.ApplyButton
                type="primary"
                disabled={hasNoFormOfMapSelection}
                onClick={() => {
                  const selectionSet = new Set(visibleWellsList);

                  const wellNames = wellsVm
                    ?.filter((wellVm) => selectionSet?.has(wellVm.name))
                    .map((wellVm) => wellVm.name);

                  dispatch({
                    type: "INTEL_DASHBOARD_SET_MAP_SELECTED_WELLS",
                    payload: wellNames ?? [],
                  });

                  setIsMapModalOpen(false);
                }}
              >
                Apply
              </Styled.ApplyButton>
            </Tooltip>
            <PDComponent.SvgIcon
              color={themeStyle.colors.faint_typography}
              height={24}
              width={24}
              style={{
                padding: "0px 12px",
                marginRight: 12,
              }}
              onClick={() => {
                setIsMapModalOpen(false);
              }}
              name="close"
            />
          </Styled.HeaderMap>
          <Suspense fallback={null}>
            <ErrorBoundary fallback={<WebGLSupportBanner />}>
              {wellsVm ? (
                <AgnosticMapView
                  isInsideModal={true}
                  isMapOpen={isMapModalOpen}
                  wellsOptions={wellsVm}
                  onWellsMapChange={handleOnWellsMapChange}
                />
              ) : null}
            </ErrorBoundary>
          </Suspense>
        </Suspense>
      </Modal>
    </>
  );
};
