import { useMutation } from "@tanstack/react-query";
import type {
  ApiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGetRequest,
  ScorecardKpiCommentDto,
} from "apis/oag";
import { ClaraApi } from "apis/oag";
import { useState } from "react";
import { apiConfig } from "utils/apiConfig";

const claraApi = new ClaraApi(apiConfig);

export const useCommentGenerate = ({
  onSuccess,
}: {
  onSuccess: (data: ScorecardKpiCommentDto) => void;
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const mutation = useMutation({
    mutationFn: (
      request: ApiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGetRequest,
    ) => {
      return claraApi.apiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGet(
        request,
      );
    },
    onMutate: () => {
      setIsGenerating(true);
    },
    onSuccess(data) {
      onSuccess(data);
    },
    onSettled() {
      setIsGenerating(false);
    },
  });
  return {
    isGenerating,
    generateComment: mutation.mutate,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  };
};
