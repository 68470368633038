/* eslint-disable react/forbid-dom-props */
import type { FilterFnOption } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import type {
  IntelScorecardFactDto,
  IntelScorecardUserLensDto,
} from "apis/oag";
import { DimensionType, IntelGroupingType, TimeUnit } from "apis/oag";
import { IntelLegendColorsContext } from "pages/IntelDashboard/useIntelLegendColors";
import { useContext, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { useMetresPerTimeUnitUom, useTimeUom, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

const BASE_COLUMN_SIZE = 160;

export enum COLUMNS_IDS {
  lensGrouping = "lens-grouping",
  rig = "rig",
  operator = "operator",
  basin = "basin",
  operatorRank = "operator-rank",
  basinAvgComp = "basin-avg-comp",
  basinRank = "basin-rank",
  wellEndDate = "well-end-date",
  drillingProd = "drilling-prod",
  averageDays = "average-days",
  averageMD = "average-md",
  quartile = "quartile",
}
const FILTER_FUNCTION: FilterFnOption<IntelScorecardFactDto> =
  "arrIncludesSome";
export const useColumns = ({ lens }: { lens: IntelScorecardUserLensDto }) => {
  const columnHelper = createColumnHelper<IntelScorecardFactDto>();
  const uom = useMetresPerTimeUnitUom(TimeUnit.Day);
  const depthUom = useUOM(DimensionType.Metres);
  const timeUom = useTimeUom(TimeUnit.Day);
  const { isDark } = useCustomTheme();
  const { getColor } = useContext(IntelLegendColorsContext);

  const legendSelectedGroup = useAppSelector(
    (state) => state.intelDashboard.groupingType,
  );
  return useMemo(() => {
    return [
      columnHelper.accessor("key.lensKey", {
        cell: (info) => {
          return (
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  backgroundColor: getColor({
                    key: info.row.original.key.queryKey,
                  }),
                  width: 16,
                  height: 16,
                  maxWidth: 16,
                  maxHeight: 16,
                  minWidth: 16,
                  minHeight: 16,
                  borderRadius: 8,
                  display: "inline-block",
                  marginRight: 8,
                }}
              />
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {info.getValue()}
              </span>
            </span>
          );
        },
        filterFn: FILTER_FUNCTION,
        header: `${lens.grouping} ${
          (
            [
              IntelGroupingType.Operator,
              IntelGroupingType.Rig,
            ] as IntelGroupingType[]
          ).includes(lens.grouping)
            ? ""
            : "Name"
        }`,
        id: COLUMNS_IDS.lensGrouping,
        minSize: 250,
        enableColumnFilter: true,
      }),

      columnHelper.accessor("key.queryKey", {
        cell: (info) => info.getValue(),
        header: `${legendSelectedGroup} ${
          (
            [
              IntelGroupingType.Operator,
              IntelGroupingType.Rig,
            ] as IntelGroupingType[]
          ).includes(legendSelectedGroup)
            ? ""
            : "Name"
        }`,
        id: COLUMNS_IDS.rig,
        minSize: 250,
        filterFn: FILTER_FUNCTION,
        enableColumnFilter: true,
      }),
      columnHelper.accessor("wellDetails.endDate", {
        cell: (info) =>
          formatTime(info.getValue(), {
            formatStr: "MM/D/YY",
          }),
        header: "Well End Date",
        id: COLUMNS_IDS.wellEndDate,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("productivity", {
        cell: (info) => {
          return (
            <span>
              {uom.display(info.getValue(), {
                fractionDigits: 0,
                unit: "",
              })}
              <span
                style={{
                  color: colors.necron_compound,
                }}
              >
                {uom.abbr}
              </span>
            </span>
          );
        },
        header: "Drilling Prod.",
        enableColumnFilter: false,
        id: COLUMNS_IDS.drillingProd,
      }),
      columnHelper.accessor("drillingTime", {
        cell: (info) => (
          <span>
            {timeUom.display(info.getValue(), {
              fractionDigits: 0,
              unit: "",
            })}
            <span
              style={{
                color: colors.necron_compound,
              }}
            >
              {timeUom.abbr}
            </span>
          </span>
        ),
        header: "Avg. Days",
        id: COLUMNS_IDS.averageDays,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("measuredDepth", {
        cell: (info) => {
          return (
            <span>
              {depthUom.display(info.getValue(), {
                fractionDigits: 0,
                unit: "",
              })}
              <span
                style={{
                  color: colors.necron_compound,
                }}
              >
                {depthUom.abbr}
              </span>
            </span>
          );
        },
        header: "Avg. MD",
        id: COLUMNS_IDS.averageMD,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("basin.productivityDeviation", {
        cell: (info) => {
          const value = info.getValue() * 100;
          const getBackgroundColor = (value: number) => {
            if (value > 0) {
              return isDark
                ? colors.positive_green_dark
                : colors.positive_green_light;
            }
            return isDark
              ? colors.negative_red_dark
              : colors.negative_red_light;
          };
          return (
            <span
              style={{
                backgroundColor: getBackgroundColor(value),
                width: 50,
                height: "auto",
                borderRadius: 16,
                padding: "1px 10px",
              }}
            >
              {value.toFixed(2)}%
            </span>
          );
        },
        header: "Basin Avg. Comp",
        id: COLUMNS_IDS.basinAvgComp,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("basin.rank", {
        cell: (info) => info.getValue(),
        header: "Basin Rank",
        id: COLUMNS_IDS.basinRank,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("basin.name", {
        cell: (info) => info.getValue(),
        header: "Basin",
        id: COLUMNS_IDS.basin,
        filterFn: FILTER_FUNCTION,
        enableColumnFilter: true,
      }),
      columnHelper.accessor("quartile", {
        cell: (info) => {
          const value = info.getValue();
          const getBackgroundColor = (value: number) => {
            switch (value) {
              case 1:
                return isDark ? "#07A03266" : "rgba(7, 160, 50, 0.30)";
              case 2:
                return isDark ? "#47A0AB66" : "rgba(71, 160, 171, 0.30)";
              case 3:
                return isDark ? "#F2D87A66" : "rgba(242, 216, 122, 0.30)";
              case 4:
                return isDark ? "#C4877D66" : "rgba(196, 135, 125, 0.30)";
              default:
                return colors.white;
            }
          };
          return (
            <span
              style={{
                backgroundColor: getBackgroundColor(value),
                height: "auto",
                borderRadius: 16,
                width: "74px",
                padding: "4px 21px",
              }}
            >
              {value}
            </span>
          );
        },
        header: "Quartile",
        id: COLUMNS_IDS.quartile,
        enableColumnFilter: false,
      }),

      columnHelper.accessor("operator.rank", {
        cell: (info) => info.getValue(),
        header: "Operator Rank",
        size: BASE_COLUMN_SIZE,
        id: COLUMNS_IDS.operatorRank,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("operator.name", {
        cell: (info) => info.getValue(),
        header: "Operator Name",
        id: COLUMNS_IDS.operator,
        filterFn: FILTER_FUNCTION,
        enableColumnFilter: true,
      }),
    ];
  }, [
    columnHelper,
    depthUom,
    isDark,
    legendSelectedGroup,
    lens.grouping,
    timeUom,
    uom,
    getColor,
  ]);
};
