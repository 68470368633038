import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridColumns, GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Circle } from "@visx/shape";
import {
  DimensionType,
  type IntelScatterPlotFactDto,
  type IntelScatterPlotUserLensDto,
  ResultDataState,
  TimeUnit,
} from "apis/oag";
import {
  AxisLocation,
  StandardTickComponent,
} from "components/Lenses/common/ChartElements";
import NoData from "components/Lenses/common/NoData";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import {
  getAxisFontSize,
  LATERAL_AXIS_TEXT_WIDTH,
  LATERAL_AXIS_WIDTH,
  LATERAL_LABEL_POSITION,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { extent } from "d3-array";
import {
  getDisplayScale,
  useYAxisDisplayScale,
} from "hooks/charting/useYAxisDisplayScale";
import { useIntelScatterPlotFacts } from "hooks/facts/useIntelScatterPlotFacts";
import { Quartiles } from "pages/IntelDashboard/components/common/Quartiles";
import { IntelLegendColorsContext } from "pages/IntelDashboard/useIntelLegendColors";
import type { RefObject } from "react";
import { useContext, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { APPROX_CHAR_WIDTH } from "utils/constants";
import { useMetresPerTimeUnitUom, useUOM } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

import { LegendIndicator, LegendTableRow, TooltipInfoTable } from "./style";
import {
  BOTTOM_AXIS_HEIGHT,
  BOTTOM_AXIS_PADDING,
  CHART_HORIZONTAL_PADDING,
  CHART_MARGIN,
  CHART_VERTICAL_PADDING,
} from "./utils";

const getKey = (point: IntelScatterPlotFactDto) => {
  return `${point.key.queryKey}-${point.key.lensKey}`;
};

export const Chart = ({
  lens,
  chartWidth,
  chartHeight,
  chartRef,
  showQuartile,
}: {
  lens: IntelScatterPlotUserLensDto;
  chartRef: RefObject<HTMLDivElement>;
  chartWidth: number;
  chartHeight: number;
  showQuartile: boolean;
}) => {
  const { data } = useIntelScatterPlotFacts(lens.id, lens.grouping);
  const dotsData = useMemo(
    () => (data.dataState === ResultDataState.NoData ? [] : data.facts),
    [data],
  );

  const quartileThresholds = useMemo(() => data.quartileThresholds, [data]);

  const plotHeight = getSVGNormalizedValue(
    chartHeight - BOTTOM_AXIS_HEIGHT - CHART_MARGIN,
  );
  const plotWidth = getSVGNormalizedValue(
    chartWidth - LATERAL_AXIS_WIDTH - CHART_MARGIN,
  );

  const xScale = useMemo(() => {
    const [min = 0, max = 0] = extent<number>(
      (dotsData ?? []).map((d) => d.measuredDepth),
    );
    return scaleLinear<number>({
      range: [0, plotWidth - CHART_HORIZONTAL_PADDING],
      domain: [min, max],
      nice: true,
    });
  }, [plotWidth, dotsData]);

  const yScale = useMemo(() => {
    const [min = 0, max = 0] = extent<number>(
      (dotsData ?? []).map((d) => d.productivity),
    );

    return scaleLinear<number>({
      range: [CHART_VERTICAL_PADDING, plotHeight],
      domain: [max, min],
      nice: true,
    });
  }, [plotHeight, dotsData]);
  const { getColor } = useContext(IntelLegendColorsContext);

  const highlightedIds = useAppSelector(
    (state) => state.intelDashboard.highlightedIds,
  );

  const depthTransformer = useUOM(DimensionType.Metres);
  const productivityTransformer = useMetresPerTimeUnitUom(TimeUnit.Day);
  const { verticalAxisTicksCount, yDisplayOnlyScale, formatTickDecimals } =
    useYAxisDisplayScale({
      originalScale: yScale,
      uom: productivityTransformer,
      tickContainerHeight: plotHeight,
    });
  const xAxisDisplayScale = getDisplayScale({
    originalScale: xScale,
    uom: depthTransformer,
  });
  const { showTooltip, hideTooltip, tooltipElement } =
    useChartTooltip<IntelScatterPlotFactDto>({
      containerRef: chartRef,
      renderContent: ({ tooltipData }) => {
        if (!tooltipData) return null;
        const legendColor = getColor({
          key: tooltipData.key.queryKey,
        });
        return (
          <TooltipInfoTable>
            <tbody>
              <tr>
                <td>
                  <TooltipHighlightValue>
                    {lens.grouping} Name
                  </TooltipHighlightValue>
                </td>
                <td>
                  <TooltipHighlightValue fontWeight="bold">
                    {tooltipData.key.lensKey}
                  </TooltipHighlightValue>
                </td>
              </tr>
              <tr>
                <td>
                  <TooltipHighlightValue>Productivity</TooltipHighlightValue>
                </td>
                <td>
                  <TooltipHighlightValue fontWeight="bold">
                    {productivityTransformer.display(tooltipData.productivity)}
                  </TooltipHighlightValue>
                </td>
              </tr>
              <tr>
                <td>
                  <TooltipHighlightValue>Measured Depth</TooltipHighlightValue>
                </td>
                <td>
                  <TooltipHighlightValue fontWeight="bold">
                    {depthTransformer.display(tooltipData.measuredDepth)}
                  </TooltipHighlightValue>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <LegendTableRow>
                <td>
                  <TooltipHighlightValue>Legend</TooltipHighlightValue>
                </td>
                <td>
                  <LegendIndicator color={legendColor} />
                  <TooltipHighlightValue fontWeight="bold">
                    {tooltipData.key.queryKey}
                  </TooltipHighlightValue>
                </td>
              </LegendTableRow>
            </tfoot>
          </TooltipInfoTable>
        );
      },
    });

  const dots = useMemo(
    () =>
      (dotsData ?? []).map((d) => {
        const legendColor = getColor({
          key: d.key.queryKey,
        });
        return (
          <Circle
            key={getKey(d)}
            fill={
              highlightedIds &&
              (highlightedIds.has(d.key.queryKey) || highlightedIds.size === 0)
                ? legendColor
                : colors.gray
            }
            fillOpacity={
              highlightedIds &&
              (highlightedIds.has(d.key.queryKey) || highlightedIds.size === 0)
                ? 1
                : 0.15
            }
            cx={xScale(d.measuredDepth)}
            cy={yScale(d.productivity)}
            r={2.5}
            onMouseEnter={() => {
              showTooltip({
                tooltipLeft: xScale(d.measuredDepth) + LATERAL_AXIS_WIDTH,
                tooltipTop: yScale(d.productivity),
                tooltipData: d,
              });
            }}
            onMouseLeave={() => hideTooltip()}
          />
        );
      }),
    [
      dotsData,
      xScale,
      yScale,
      highlightedIds,
      showTooltip,
      hideTooltip,
      getColor,
    ],
  );

  const {
    themeStyle: { intel: intelColors },
  } = useCustomTheme();
  const axisFontSize = useMemo(() => getAxisFontSize(false), []);

  const bottomNumTicks =
    plotWidth /
    (xAxisDisplayScale.domain()[1].toString().length * APPROX_CHAR_WIDTH +
      BOTTOM_AXIS_PADDING);
  if (!dotsData) return null;
  if (dotsData.length === 0) return <NoData />;
  return (
    <svg
      width={chartWidth}
      height={chartHeight}
      style={{
        overflow: "show",
        marginTop: CHART_MARGIN,
      }}
    >
      <GridRows
        scale={yDisplayOnlyScale}
        width={plotWidth}
        height={plotHeight}
        numTicks={verticalAxisTicksCount}
        stroke={intelColors.grid_lines}
        strokeWidth={1}
        strokeOpacity={1}
        left={LATERAL_AXIS_WIDTH}
      />

      <GridColumns
        scale={xAxisDisplayScale}
        width={plotWidth}
        height={plotHeight}
        numTicks={bottomNumTicks}
        stroke={intelColors.grid_lines}
        strokeWidth={2}
        strokeOpacity={1}
        strokeDasharray="2,4"
        left={LATERAL_AXIS_WIDTH}
      />

      {showQuartile ? (
        <Quartiles
          plotHeight={plotHeight}
          plotWidth={plotWidth}
          yScale={yScale}
          quartileThresholds={quartileThresholds}
        />
      ) : null}
      <AxisLeft
        label={`Productivity (${productivityTransformer.abbr})`}
        labelOffset={LATERAL_LABEL_POSITION - LATERAL_AXIS_TEXT_WIDTH}
        scale={yDisplayOnlyScale}
        numTicks={verticalAxisTicksCount}
        hideTicks
        labelProps={{
          fill: intelColors.typography,
          fontSize: axisFontSize,
          textAnchor: "middle",
        }}
        left={LATERAL_AXIS_WIDTH - LATERAL_AXIS_TEXT_WIDTH - 5}
        hideAxisLine
        tickComponent={(props) => (
          <StandardTickComponent rendererProps={props}>
            {(val) => formatTickDecimals(val)}
          </StandardTickComponent>
        )}
      />
      <AxisBottom
        label={`Measured Depth (${depthTransformer.abbr})`}
        labelProps={{
          fill: intelColors.typography,
          fontSize: axisFontSize,
          textAnchor: "start",
        }}
        hideAxisLine
        hideZero
        hideTicks
        numTicks={bottomNumTicks}
        scale={xAxisDisplayScale}
        top={plotHeight}
        tickComponent={(props) => (
          <StandardTickComponent
            axisLocation={AxisLocation.BOTTOM}
            rendererProps={props}
          >
            {(val) => formatTickDecimals(val)}
          </StandardTickComponent>
        )}
      />
      <Group left={LATERAL_AXIS_WIDTH}>{dots}</Group>
      {tooltipElement}
    </svg>
  );
};
