import { IntelGroupingType } from "apis/oag";
import type { RootState } from "reducers/store";

import type { IAction } from "./types";

type IdType = string;
interface IntelDashboardReducerState {
  highlightedIds: Set<IdType> | null;
  groupingType: IntelGroupingType;
  hasAutoSelectedQuarterYearList: boolean;
  hasAutoSelectedFilters: boolean;
  mapSelectedWells: string[] | null;
}

const initialState: IntelDashboardReducerState = {
  highlightedIds: new Set(),
  groupingType: IntelGroupingType.Contractor,
  hasAutoSelectedQuarterYearList: false,
  hasAutoSelectedFilters: false,
  mapSelectedWells: null,
};

interface ISetGroupingTypeAction extends IAction {
  type: "INTEL_DASHBOARD_SET_GROUPING_TYPE";
  payload: IntelGroupingType;
}

interface ISetToggleHighlightedIdAction extends IAction {
  type: "INTEL_DASHBOARD_TOGGLE_HIGHLIGHTED_KEY";
  payload: IdType;
}

interface IResetDashboardToInitialState extends IAction {
  type: "INTEL_DASHBOARD_RESET_TO_INITIAL_STATE";
}

interface ISetQuarterYearListAsAutoSelected extends IAction {
  type: "INTEL_SET_QUARTER_YEAR_LIST_AS_AUTOSELECTED";
  payload: boolean;
}

interface ISetFiltersListAsAutoSelected extends IAction {
  type: "INTEL_SET_FILTERS_LIST_AS_AUTOSELECTED";
  payload: boolean;
}

interface ISetMapSelectedWells extends IAction {
  type: "INTEL_DASHBOARD_SET_MAP_SELECTED_WELLS";
  payload: string[] | null;
}

type AvailableActions =
  | ISetGroupingTypeAction
  | ISetToggleHighlightedIdAction
  | IResetDashboardToInitialState
  | ISetQuarterYearListAsAutoSelected
  | ISetFiltersListAsAutoSelected
  | ISetMapSelectedWells;

function intelDashboardReducer(
  state: IntelDashboardReducerState = initialState,
  action: AvailableActions,
): IntelDashboardReducerState {
  switch (action.type) {
    case "INTEL_DASHBOARD_SET_GROUPING_TYPE": {
      return {
        ...state,
        highlightedIds: new Set(),
        groupingType: action.payload,
      };
    }

    case "INTEL_DASHBOARD_TOGGLE_HIGHLIGHTED_KEY": {
      const newHighlightedIds = new Set(state.highlightedIds);

      if (newHighlightedIds.has(action.payload)) {
        newHighlightedIds.delete(action.payload);
      } else {
        newHighlightedIds.add(action.payload);
      }

      return {
        ...state,
        highlightedIds: newHighlightedIds,
      };
    }

    case "INTEL_SET_QUARTER_YEAR_LIST_AS_AUTOSELECTED":
      return {
        ...state,
        hasAutoSelectedQuarterYearList: action.payload,
      };

    case "INTEL_SET_FILTERS_LIST_AS_AUTOSELECTED":
      return {
        ...state,
        hasAutoSelectedFilters: action.payload,
      };

    case "INTEL_DASHBOARD_SET_MAP_SELECTED_WELLS":
      return {
        ...state,
        mapSelectedWells: action.payload,
      };

    case "INTEL_DASHBOARD_RESET_TO_INITIAL_STATE":
      return initialState;

    default:
      return state;
  }
}

export const selectIntelFiltersWithMap = (state: RootState) => {
  const {
    intelFiltersCommitted,
    intelDashboard: { mapSelectedWells },
  } = state;

  return {
    ...intelFiltersCommitted,
    wellNames: mapSelectedWells ?? intelFiltersCommitted.wellNames,
  };
};

export default intelDashboardReducer;
