import { Button } from "atoms/Form";
import { StyledTabs } from "components/Layout/Tabbed";
import { PDComponent } from "components/PDComponents";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Checkbox, Skeleton } from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 24px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const ModalWrapper = styled(PDComponent.Modal)`
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};

  ${PdCardBody} {
    width: 600px;
  }
`;

export const SpinContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Description = styled.div`
  width: 100%;
  min-height: 67px;
  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  line-height: 130%;
`;

export const WellNumber = styled.span`
  font-size: 14px;
  font-weight: 500;
  padding-right: 8px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;
export const WellName = styled.span`
  font-size: 16px;
  font-weight: 500;
  padding-right: 8px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;
export const RigName = styled.span`
  padding-left: 8px;
  font-size: 16px;
  font-weight: 400;
`;

export const Kpi = styled.span<{ $isWhite?: string }>`
  font-size: 14px;
  ${({ $isWhite }) =>
    $isWhite
      ? css`
          color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
        `
      : ""}
`;

export const Scores = styled.div`
  font-size: 12px;
  line-height: 140%;
`;

export const TabsContainer = styled.div`
  overflow: visible;
  .ant-tabs-tab {
    background-color: transparent !important;
    border-color: transparent !important;
    &.ant-tabs-tab-active {
      border-bottom: 4px solid
        ${({ theme }) => theme.themeStyle.colors.primary_button_bg} !important;
    }
  }
  ${StyledTabs} {
    margin-top: 12px;
  }
`;

export const KpiGroup = styled.div<{ isNegative?: boolean }>`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ theme, isNegative }) =>
    isNegative
      ? colors.coral_expression
      : theme.themeStyle.colors.primary_typography};
`;

export const CommentListContainer = styled.div`
  height: 100%;
  max-height: 378px;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100%);

  ::-webkit-scrollbar {
    width: 4px;
  }
`;

export const CommentIconContainer = styled.div`
  padding: 3px;
`;

export const CommentIcon = styled.div`
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  border-radius: 50%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const StandCommentMain = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`;

export const DateText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const FadedText = styled.span`
  font-size: 12px;
  padding-left: 6px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CommentHeaderDescription = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
`;
export const CommentHeaderDescriptionRight = styled(CommentHeaderDescription)`
  text-align: right;
`;

export const CommentHeaderControls = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 125px;
  font-size: 12px;
  gap: 11px;
`;

export const Author = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-size: 14px;
  font-weight: 500;
`;
export const Text = styled(Author)`
  font-size: 12px;
`;

export const CommentContainer = styled.div<{ $paddingTop: number }>`
  min-height: 50px;
  display: flex;
  padding-top: ${({ $paddingTop }) => $paddingTop}px;
  padding-right: 12px;
  gap: 14px;

  :last-child {
    padding-bottom: 32px;
  }
`;

export const ExistingCommentContents = styled.p`
  white-space: pre-wrap;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  margin-top: 8px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.2px;
`;

export const ExistingComment = styled.div`
  overflow: hidden;
  flex-grow: 1;
`;

export const NewComment = styled.div`
  width: 100%;

  textarea.ant-input {
    height: 155px;
    resize: none;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

export const AddComment = styled.div`
  color: ${colors.well_color} !important;
  font-size: 14px;
  min-height: 40px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const CommentActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  padding-top: 8px;
`;

export const PlusIcon = styled(PDComponent.SvgIcon)`
  height: 24px;
  font-size: 24px;

  &:hover {
    cursor: pointer;
  }
`;

export const AddNoteText = styled.span`
  line-height: 22px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const CommentButton = styled.div<{ color?: string }>`
  user-select: none;
  height: 12px;
  display: flex;
  font-size: 12px;
  border: 0;
  gap: 5px;
  color: ${({ theme, color }) =>
    color || theme.themeStyle.colors.primary_typography};

  &:hover {
    cursor: pointer;
  }
`;

export const CommentActionsLeft = styled.div`
  width: 100%;
`;

export const LoadingSkeleton = styled(Skeleton)`
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    height: 8px;
    ::after {
      background: linear-gradient(
        90deg,
        rgba(112, 251, 198, 0.15) 15%,
        rgba(112, 251, 198, 0.3) 35%,
        #70fbc6 50%,
        rgba(112, 251, 198, 0.3) 65%,
        rgba(112, 251, 198, 0.15) 85%
      ) !important;
    }
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 8px;
  }
`;

export const SkeletonContainer = styled.div`
  height: 155px;
  display: flex;
  padding: 8px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const ExceptionsContainer = styled.div<{ $isDisabled: boolean }>`
  ${({ $isDisabled }) => ($isDisabled ? `opacity: 0.35;` : null)}
  width: 100%;
`;

export const CommentGeneratorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SuccessText = styled.div`
  color: #25a791;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ErrorText = styled(SuccessText)`
  color: #d66e6b;
`;

export const ButtonText = styled.div`
  background: radial-gradient(
      ${colors.illuminating_emerald},
      ${colors.iridescent_turquoise}
    )
    text !important;
  background-clip: text;
  font-weight: 500;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const IconContainer = styled.div<{ $isError: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme: { isDark } }) =>
      isDark
        ? `linear-gradient(111deg, rgba(74, 74, 80, 0.20) -2.91%, rgba(74, 74, 80, 0.00) -2.9%, rgba(74, 74, 80, 0.00) 17.95%, rgba(74, 74, 80, 0.20) 19.42%, rgba(74, 74, 80, 0.20) 34.01%, rgba(74, 74, 80, 0.00) 38.67%, rgba(74, 74, 80, 0.00) 50.86%, rgba(74, 74, 80, 0.20) 53.07%, rgba(74, 74, 80, 0.20) 66.11%, rgba(74, 74, 80, 0.00) 72.21%), radial-gradient(0.86% 50% at 50% 50%, rgba(73, 67, 65, 0.10) 4%, rgba(51, 51, 58, 0.10) 100%)`
        : `linear-gradient(121deg, rgba(255, 255, 255, 0.20) 15.4%, rgba(255, 255, 255, 0.00) 28.19%, rgba(255, 255, 255, 0.00) 32.51%, rgba(255, 255, 255, 0.20) 52.4%, rgba(255, 255, 255, 0.00) 64.09%, rgba(255, 255, 255, 0.00) 74.03%, rgba(255, 255, 255, 0.20) 84.67%), radial-gradient(64.54% 60.02% at 50% 50%, rgba(255, 255, 255, 0.30) 31.5%, rgba(169, 169, 169, 0.15) 100%)`},
    ${({ theme }) => theme.themeStyle.clara.CTA_bg};

  backdrop-filter: blur(9px);
  border: 2px solid ${({ theme }) => theme.themeStyle.clara.icon_border}20;
  backdrop-filter: blur(9px);
`;

export const GeneratorButton = styled(Button)`
  display: inline-flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  :focus,
  :focus-visible,
  :hover {
    outline: none !important;
    border-color: transparent !important;
  }
  transition: all 0.01s;
  background: ${({ theme: { isDark } }) =>
    isDark
      ? `radial-gradient(44.41% 54.17% at 55.59% 54.17%, #2C2C31 0%, #26262A 100%)`
      : `radial-gradient(44.41% 54.17% at 55.59% 54.17%, #FFF 0%, #F1F1F1 100%)`} !important;

  box-shadow:
    1px 1px 3px 0px rgba(255, 255, 255, 0.3) inset,
    1px 1px 3px -1px rgba(0, 0, 0, 0.2) !important;
  &[disabled] {
    ${ButtonText} {
      color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
      background: ${({ theme }) => theme.themeStyle.colors.disabled_typography}
        text !important;
    }
    ${IconContainer} {
      position: relative;
      color: ${({ theme }) => theme.themeStyle.clara.icon_bg};
      background: transparent;
      ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${({ theme }) => theme.themeStyle.clara.icon_bg};
        border-radius: 50%;
      }
    }
  }
`;

export const GeneratorButtonContainer = styled.div<{
  $isGenerating: boolean;
  $disabled: boolean;
}>`
  padding: 4px 4px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  box-shadow: ${({ theme: { isDark } }) =>
    isDark
      ? `-1px -1px 3px 0px rgba(255, 255, 255, 0.15),
    1px 1px 1px 0px rgba(0, 0, 0, 0.15) inset,
    0px 2px 4px -2px rgba(0, 0, 0, 0.5),
    0px 0px 10px 0px rgba(44, 44, 44, 0.4) inset,
    0px -2px 10px -3px rgba(0, 0, 0, 0.3) inset,
    0px 8px 24px -16px rgba(0, 0, 0, 0.4) inset`
      : `-1px -1px 3px 0px rgba(0, 0, 0, 0.15),
    1px 1px 1px 0px rgba(255, 255, 255, 0.15) inset,
    0px 2px 4px -2px rgba(255, 255, 255, 0.5),
    0px 0px 10px 0px rgba(211, 211, 211, 0.4) inset,
    0px -2px 10px -3px rgba(255, 255, 255, 0.3) inset,
    0px 8px 24px -16px rgba(255, 255, 255, 0.4) inset`};
  position: relative;
  background: ${({ $isGenerating, theme }) =>
    $isGenerating
      ? colors.iridescent_turquoise
      : theme.themeStyle.colors.white_black};

  border: ${({ $isGenerating, theme }) =>
    $isGenerating
      ? `4px solid ${theme.themeStyle.clara.generating_bg}`
      : "none"};

  background: ${({ $isGenerating, theme }) =>
    $isGenerating && !theme.isDark ? "transparent" : null};
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 8px;
    background: ${colors.iridescent_turquoise}33;
    filter: blur(5px);
  }
  :hover {
    background: ${({ $disabled }) =>
      $disabled ? null : colors.iridescent_turquoise};
    ::before {
      opacity: ${({ $disabled }) => ($disabled ? 0 : 1)};
    }
  }
`;
export const CommentActionsRight = styled.div`
  display: flex;
  gap: 4px;
  align-self: flex-end;
`;

export const ExceptionLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  grid-column: 1 / 3;

  &:hover {
    cursor: pointer;
  }
`;

export const ExceptionCheckbox = styled(Checkbox)`
  padding-right: 4px;

  .ant-checkbox {
    top: 0;
  }
`;

export const ExceptionContainer = styled.div<{ $isDisabled?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 80px auto;
  grid-template-rows: auto 1fr;
  padding-right: 16px;
  gap: 8px;
`;

export const InputWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DropdownLabel = styled.label`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const ExceptionInfoAboutCheckbox = styled.span`
  font-size: 12px;
  grid-column: 1/3;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const TooltipText = styled.span<{ $color?: string }>`
  color: ${({ theme, $color }) =>
    $color ?? theme.themeStyle.colors.disabled_typography} !important;
  width: 300px;
`;

export const NoCommentsContainer = styled.div`
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  h1 {
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.2px;
  }
`;

export const StyledSeparator = styled.div`
  ${Kpi}:last-of-type::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 4px;
    background: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    opacity: 0.12;
  }
  ${Kpi}:first-of-type:last-of-type::before {
    display: none;
  }
`;
