import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Button } from "atoms/Form";
import type { FilterTypes } from "components/Filters/useFiltersCategories";
import { useFiltersCategories } from "components/Filters/useFiltersCategories";
import FiltersPopup from "components/General/FiltersPopup";
import SelectFilter from "components/General/SelectFilter";
import { PDComponent } from "components/PDComponents";
import { additionalLinkedFilters } from "hooks/filters/useFilterTags";
import type { AttributeStore } from "hooks/filters/useLinkedAttributes";
import React from "react";
import { useEffect, useMemo, useState } from "react";
import type { IFilterOptions } from "reducers/rigsCommonReducer";
import { Popover, Tooltip } from "utils/componentLibrary";

const FILTER_UNTOUCHED = null;

export const UNINITIALIZED_OPTIONS = {
  rigs: FILTER_UNTOUCHED,
  operators: FILTER_UNTOUCHED,
  formations: FILTER_UNTOUCHED,
  wells: FILTER_UNTOUCHED,
  wellStatus: FILTER_UNTOUCHED,
  rigType: FILTER_UNTOUCHED,
  horsePower: FILTER_UNTOUCHED,
  operatingCenter: FILTER_UNTOUCHED,
  technology: FILTER_UNTOUCHED,
  country: FILTER_UNTOUCHED,
};

export type LinkedAttributesOptions =
  | {
      id: number;
      name: string;
    }[]
  | null;

export type LinkedAttributesOptionsString =
  | {
      id: string;
      name: string;
    }[]
  | null;

export const INITIAL_FILTERS: IFilterOptions = {
  wellStatus: FILTER_UNTOUCHED,
  rigType: FILTER_UNTOUCHED,
  horsePower: FILTER_UNTOUCHED,
  country: FILTER_UNTOUCHED,
  operatingCenter: FILTER_UNTOUCHED,
  technology: FILTER_UNTOUCHED,
  holeSection: [],
  directionalInterval: [],
  holeSize: [],
  phases: [],
};

const AllFiltersInternal = ({
  filters,
  setFilters,
  scrollElement,
  disabledFilters,
  attributeStoreName,
}: {
  filters: IFilterOptions | null;
  setFilters: (filters: IFilterOptions | null) => void;
  scrollElement?: HTMLElement | Document | null;
  disabledFilters?: (keyof IFilterOptions)[];
  attributeStoreName: AttributeStore;
}) => {
  const [isFilterPopupVisible, setIsFilterPopupVisible] = useState(false);

  const {
    initialFilters,
    localFilters,
    setLocalFilters,
    allFilters,
    toggleFilter,
  } = useFiltersCategories(filters, setFilters, attributeStoreName);

  const isAllSelected = useMemo(() => {
    if (!localFilters) return true;

    const unlinkedFiltersAreUntouched = localFilters
      ? Object.keys(localFilters ?? []).every(
          (key) =>
            additionalLinkedFilters.includes(
              key as (typeof additionalLinkedFilters)[number],
            ) ||
            localFilters[key as FilterTypes]?.length ===
              allFilters[key as FilterTypes].options.length,
        )
      : false;

    const linkedFiltersAreUntouched = additionalLinkedFilters.every((lfKey) => {
      const lf = localFilters?.[lfKey];
      return lf === FILTER_UNTOUCHED;
    });

    return unlinkedFiltersAreUntouched && linkedFiltersAreUntouched;
  }, [allFilters, localFilters]);

  useEffect(() => {
    const onScroll = () => {
      setIsFilterPopupVisible(false);
    };

    scrollElement?.addEventListener("scroll", onScroll);
    return () => {
      scrollElement?.removeEventListener("scroll", onScroll);
    };
  }, [scrollElement]);

  const toggleAll = (filterType: FilterTypes) => (e: CheckboxChangeEvent) => {
    setLocalFilters((prev) => {
      const newFilters: IFilterOptions = prev ? { ...prev } : INITIAL_FILTERS;

      if (e.target.checked) {
        newFilters[filterType] = allFilters[filterType].options;
      } else {
        newFilters[filterType] = [];
      }
      return newFilters;
    });
  };

  const deselectAll = () => {
    if (isAllSelected) {
      setLocalFilters({
        rigType: [],
        horsePower: [],
        country: [],
        operatingCenter: [],
        technology: [],
        wellStatus: [],
        holeSection: [],
        directionalInterval: [],
        holeSize: [],
        phases: [],
      });
    } else {
      setLocalFilters(initialFilters);
    }
  };

  const onCancel = () => {
    setIsFilterPopupVisible(false);
  };

  const applyCustomization = () => {
    setIsFilterPopupVisible(false);
    setFilters(localFilters);
  };

  const visibleFilterOptions = useMemo(
    () =>
      (Object.keys(allFilters) as FilterTypes[]).filter(
        (filterType) => !disabledFilters?.includes(filterType),
      ),
    [allFilters, disabledFilters],
  );

  const getFilterValues = (filterType: FilterTypes) => {
    const currentlySelectedFilterOptions = localFilters?.[filterType];
    const allFilterOptions = allFilters[filterType].options;

    if (currentlySelectedFilterOptions === FILTER_UNTOUCHED || !localFilters) {
      // If they are set as null it means nothing specific was selected as filter.
      return allFilterOptions.map((option) => option.id ?? "");
    }

    return currentlySelectedFilterOptions?.map((option) => option.id ?? "");
  };

  const hasReachedDeadEnd = useMemo(() => {
    if (!allFilters) return false;
    return visibleFilterOptions.some(
      (filterType) => allFilters[filterType]?.options?.length === 0,
    );
  }, [allFilters, visibleFilterOptions]);

  return (
    <Tooltip title="Filter">
      <Popover
        content={
          <FiltersPopup
            isAllSelected={isAllSelected}
            trackingProps={{ page: "All Rigs" }}
            deselectAll={deselectAll}
            onCancel={onCancel}
            applyCustomization={applyCustomization}
            numRows={Math.ceil(visibleFilterOptions.length / 4)}
            numCols={4}
            onReset={
              hasReachedDeadEnd
                ? () => {
                    setLocalFilters(initialFilters);
                    setFilters(initialFilters);
                    setIsFilterPopupVisible(false);
                  }
                : undefined
            }
          >
            {visibleFilterOptions.map((filterType) => (
              <SelectFilter
                key={filterType}
                label={allFilters[filterType].categoryLabel}
                filterValues={getFilterValues(filterType)}
                options={allFilters[filterType].options.map((option) => ({
                  label: (option.value || "").toString(),
                  value: option.id || "",
                }))}
                onChangeGroup={(e) => {
                  toggleAll(filterType)(e);
                }}
                onChange={(option) => {
                  toggleFilter(filterType, option as string);
                }}
              />
            ))}
          </FiltersPopup>
        }
        trigger={["click", "scroll"]}
        placement="bottomRight"
        open={isFilterPopupVisible}
        onOpenChange={(e) => setIsFilterPopupVisible(e)}
        destroyTooltipOnHide
      >
        <Button
          size="large"
          icon={<PDComponent.SvgIcon name="filter" />}
          onClick={() => setIsFilterPopupVisible((fl) => !fl)}
          $engaged={isFilterPopupVisible}
          type={isAllSelected ? "default" : "primary"}
          ghost={!isAllSelected}
        />
      </Popover>
    </Tooltip>
  );
};

const AllFilters = React.memo(AllFiltersInternal);
AllFilters.displayName = "AllFilters";

export { AllFilters };
