import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  ActualTvdPointDto,
  ApiWellsIdTvdsPutRequest,
  BaseFocalQueryDto,
  RawTvdSeriesDto,
} from "apis/oag";
import { WellsApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls/index";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useEffectExceptOnMount } from "hooks/react-utils/useEffectExceptOnMount";
import { useAvailableFilters } from "hooks/wells/useAvailableFilters";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useCallback, useEffect, useMemo } from "react";
import { TFetchingState } from "reducers/stateReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import {
  type IFiltersType,
  initialFilters,
  type IZoomData,
} from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import { dateToDateDto } from "utils/helper";
import type { PDWellOverviewQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const wells = new WellsApi(apiConfig);

export function useTvds({
  lens,
  options,
  isLegend,
  isTimeline,
}: {
  isTimeline?: boolean;
  isLegend?: boolean;
  lens?: boolean;
  options?: Omit<
    UseQueryOptions<RawTvdSeriesDto | null>,
    "queryKey" | "queryFn"
  >;
}) {
  const wellDataID = useSelectedWell();
  const [offsetWells] = useStateQuery<Array<number>>(
    lens ? URL_STATE_PARAM.OFFSET_WIDGET : URL_STATE_PARAM.OFFSET_WELL,
    [],
  );
  const lastTvdRefreshDate = useAppSelector(
    (state) => state.dataState.lastTvdRefreshDate,
  );
  const [filters] = useStateQuery<IFiltersType>(
    URL_STATE_PARAM.FILTERS_DASHBOARD,
    initialFilters,
  );
  const [zoomData] = useStateQuery<IZoomData>(
    URL_STATE_PARAM.ZOOM_WELL,
    initialZoomData,
  );
  const dispatch = useAppDispatch();

  const { data: availableFilters } = useAvailableFilters(
    null,
    zoomData,
    filters,
    undefined,
  );

  useEffect(() => {
    if (availableFilters) {
      dispatch({
        type: "SET_AVAILABLE_FILTERS",
        payload: availableFilters,
      });
    }
  }, [availableFilters, dispatch]);

  const refetchOverview = useAppSelector(
    (state) => state.state.refetchOverview,
  );
  const requestParameters: ApiWellsIdTvdsPutRequest | null = useMemo(() => {
    const {
      sections,
      directionalIntervals,
      operationTime,
      holeSizes,
      phases: phaseIds,
    } = filters;
    const baseFocalQueryDto: BaseFocalQueryDto = {
      useMockData: false,
      wellId: wellDataID,
      sampleCount: TVD_SAMPLE_COUNT,
      selectedFilters: {
        sectionIds: sections,
        directionalIntervalIds: directionalIntervals,
        holeSizeIds: holeSizes,
        phaseIds,
        includeFlatTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNonAlphaRigs: true,
      },
      comparisonWellIds: offsetWells,
      zoomStartDepth: zoomData?.depth_start,
      zoomEndDepth: zoomData?.depth_end,
      zoomStartDuration: zoomData?.ts_start,
      zoomEndDuration: zoomData?.ts_end,
      from: offsetWells?.length
        ? defaultDateDto.from
        : dateToDateDto(zoomData?.date_start) || defaultDateDto.from,
      to: offsetWells?.length
        ? defaultDateDto.to
        : dateToDateDto(zoomData?.date_end) || defaultDateDto.to,
    };
    if (!wellDataID) return null;

    return {
      id: wellDataID,
      baseFocalQueryDto,
    };
  }, [filters, offsetWells, zoomData, wellDataID]);
  const getLimits = useCallback((series: Array<ActualTvdPointDto>) => {
    if (!series || !series[0]) return null;
    return {
      startAt: series[0].at,
      endAt: series.slice(-1)[0]?.at ?? null,
      startDepth: series[0].holeDepth,
      endDepth: series.slice(-1)[0]?.holeDepth ?? null,
    };
  }, []);

  const WellOverviewQueryKey: PDWellOverviewQueryKey = {
    uid: RequestUID.wellOverview,
    type: PDQueryType.WELL_OVERVIEW,
    wellDataID,
    params: requestParameters,
  };

  const request = useQuery<RawTvdSeriesDto | null>({
    queryKey: [WellOverviewQueryKey],
    queryFn: () => {
      if (requestParameters === null) return null;
      return wells.apiWellsIdTvdsPut(requestParameters).then((data) => {
        const payload = { [data.tvdSeries.wellId]: data.lastUpdatedAt }; // takes plan into account (for the focal well)
        (data.comparisonTvdSeries ?? []).forEach(
          (series) => (payload[series.wellId] = series.lastUpdatedAt),
        );

        if (data && !isTimeline && !isLegend && Object.keys(payload)?.length) {
          dispatch({
            type: "SET_TVD_REFRESH_DATE",
            payload,
          });
        }

        return data;
      });
    },
    ...options,
    refetchOnMount: false,
    enabled: requestParameters !== null && !!filters,
  });

  const { refetch } = request;
  useEffect(() => {
    if (isTimeline || isLegend) return;
    dispatch({
      type: "SET_TVD_FETCHING",
      payload: TFetchingState.Loading,
    });
  }, [dispatch, isTimeline, wellDataID, getLimits, request.data, isLegend]);

  useEffectExceptOnMount(() => {
    if (isTimeline || isLegend) return;
    if (refetchOverview) {
      refetch();
    }
  }, [isTimeline, lastTvdRefreshDate, refetch, refetchOverview]);

  return request;
}
