import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiIntelScatterPlotUserLensesIdFactsPutRequest,
  IntelGroupingType,
  IntelScatterPlotFactSetDto,
} from "apis/oag";
import { IntelScatterPlotUserLensesApi } from "apis/oag";
import { selectIntelFiltersWithMap } from "reducers/intelDashboardReducer";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDIntelFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new IntelScatterPlotUserLensesApi(apiConfig);

export const useIntelScatterPlotFacts = (
  lensId: number,
  lensGrouping: IntelGroupingType,
  options?: Omit<
    UseQueryOptions<IntelScatterPlotFactSetDto>,
    "queryKey" | "queryFn"
  >,
) => {
  const filterStateCommitted = useAppSelector(selectIntelFiltersWithMap);

  const groupingType = useAppSelector(
    (state) => state.intelDashboard.groupingType,
  );

  const enabled = useAppSelector(
    (state) => state.intelDashboard.hasAutoSelectedQuarterYearList,
  );

  const requestParameters: ApiIntelScatterPlotUserLensesIdFactsPutRequest = {
    id: lensId,
    wellIntelQueryDto: {
      ...filterStateCommitted,
      grouping: groupingType,
    },
  };

  const queryKey: PDIntelFactsQueryKey<ApiIntelScatterPlotUserLensesIdFactsPutRequest> =
    {
      lensId: requestParameters.id,
      type: PDQueryType.FACTS,
      uid: RequestUID.intelScatterPlot,
      params: requestParameters,
      lensGrouping,
    };

  return useSuspenseQuery<IntelScatterPlotFactSetDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      enabled
        ? api.apiIntelScatterPlotUserLensesIdFactsPut(requestParameters, {
            signal,
          })
        : new Promise(() => {}),
    ...options,
  });
};
