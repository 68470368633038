import { AreaClosed } from "@visx/shape";
import { curveBumpX } from "d3";
import type { ScaleBand, ScaleLinear } from "d3-scale";
import { IntelLegendColorsContext } from "pages/IntelDashboard/useIntelLegendColors";
import React, { useContext, useMemo } from "react";
import { useAppSelector } from "reducers/store";

import type { IntelRankingRibbonVm } from "./mappers";

const RibbonLinksInternal = ({
  d,
  hideTooltip,
  handlePointerMove,
  xAxis,
  yAxis,
  i,
  dataForSeries,
  getCurrentValue,
  data,
}: {
  d: IntelRankingRibbonVm;
  hideTooltip: () => void;
  handlePointerMove: (
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
    currentPoint: IntelRankingRibbonVm,
    index: number,
  ) => void;
  xAxis: ScaleBand<string>;
  yAxis: ScaleLinear<number, number, never>;
  i: number;
  dataForSeries: IntelRankingRibbonVm[];
  getCurrentValue: (
    d: IntelRankingRibbonVm,
    data: IntelRankingRibbonVm[],
  ) => number;
  data: IntelRankingRibbonVm[];
}) => {
  const highlightedIds = useAppSelector(
    (state) => state.intelDashboard.highlightedIds,
  );

  const height = useMemo(() => yAxis(d.productivity), [d.productivity, yAxis]);
  const next = useMemo(() => dataForSeries[i + 1], [dataForSeries, i]);

  const y = useMemo(() => getCurrentValue(d, data), [d, data, getCurrentValue]);
  const yNext = useMemo(
    () => (next ? getCurrentValue(next, data) : 0),
    [data, getCurrentValue, next],
  );
  const nextHeight = useMemo(
    () => (next ? yAxis(next.productivity) : 0),
    [next, yAxis],
  );
  const { getColor } = useContext(IntelLegendColorsContext);
  const valueInNextQuarter = useMemo(
    () =>
      Math.abs(
        xAxis.domain().indexOf(d.quarter) -
          xAxis.domain().indexOf(next?.quarter ?? ""),
      ) === 1,
    [d.quarter, next?.quarter, xAxis],
  );

  const showArea = useMemo(
    () =>
      next &&
      valueInNextQuarter &&
      ((highlightedIds?.size ?? 0) > 0
        ? highlightedIds!.has(dataForSeries[i + 1].name)
        : true),
    [dataForSeries, highlightedIds, i, next, valueInNextQuarter],
  );
  const fill = useMemo(
    () =>
      getColor({
        key: d.name,
      }),
    [d?.name, getColor],
  );
  if (!showArea) return null;

  return (
    <AreaClosed
      onMouseLeave={hideTooltip}
      onMouseMove={(e) => {
        handlePointerMove(e, next, i);
      }}
      data={[
        {
          x: (xAxis(d.quarter) ?? 0) + xAxis.bandwidth(),
          y: y,
        },
        { x: xAxis(next.quarter), y: yNext },
        {
          x: xAxis(next.quarter),
          y: yNext + nextHeight,
        },
        {
          x: (xAxis(d.quarter) ?? 0) + xAxis.bandwidth(),
          y: y + height,
        },
      ]}
      x={(d) => d.x ?? 0}
      y={(d) => d.y}
      fill={fill}
      fillOpacity={0.5}
      curve={curveBumpX}
      yScale={yAxis}
    />
  );
};

const RibbonLinks = React.memo(RibbonLinksInternal);
RibbonLinks.displayName = "RibbonLinks";
export { RibbonLinks };
