import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { FiltersApi, type WellIntelQueryDto } from "apis/oag";
import type { IntelLegendVisibleTabs } from "pages/IntelDashboard/components/LegendGroupSelector/LegendGroupSelector";
import { useMemo } from "react";
import { selectIntelFiltersWithMap } from "reducers/intelDashboardReducer";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { PDQueryType } from "utils/queryNamespaces";

const filters = new FiltersApi(apiConfig);

export const useLegendItems = <T,>(
  options?: Partial<UseSuspenseQueryOptions<WellIntelQueryDto, Error, T>>,
) => {
  const committedFilters = useAppSelector(selectIntelFiltersWithMap);

  const enabled = useAppSelector(
    (state) => state.intelDashboard.hasAutoSelectedQuarterYearList,
  );

  const linkedFiltersQueryKey = useMemo(
    () => ({
      type: PDQueryType.INTEL_LEGEND_ITEMS_FROM_LINKED_FILTERS,
      committedFilters,
    }),
    [committedFilters],
  );

  return useQuery({
    queryKey: [linkedFiltersQueryKey],
    queryFn: () => {
      return filters.apiFiltersIntelLinkedWellAttributesPut({
        wellIntelQueryDto: committedFilters,
      });
    },
    ...options,
    enabled,
    staleTime: Infinity,
    gcTime: Infinity,
    placeholderData: keepPreviousData,
  });
};

export const selectListByTab = (filterGroups: WellIntelQueryDto) => {
  const filters: Record<IntelLegendVisibleTabs, string[] | null | undefined> = {
    Rig: filterGroups.rigNames,
    Contractor: filterGroups.contractorNames,
    Operator: filterGroups.operatorNames,
  };

  return filters;
};
