import {
  DividerContainer,
  DividerLine,
} from "components/PDComponents/Divider/style";

export default function _VerticalDivider() {
  return (
    <DividerContainer>
      <DividerLine />
    </DividerContainer>
  );
}
