import type { EllipsisConfig } from "antd/lib/typography/Base";
import { Title } from "atoms/Typography";
import {
  ListItemContainer,
  StyledTitleContainer,
} from "components/PDComponents/Search/style";
import type {
  IAllowedOptions,
  IOption,
} from "components/PDComponents/Search/utils";
import { useMemo } from "react";
import type { ListChildComponentProps } from "react-window";
import { Checkbox } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

export interface OptionItemDataSource<TOption extends IAllowedOptions> {
  width?: number;
  filteredOptions: IOption<TOption>[];
  tempSelOptions: TOption[];
  onChange: (newOptions: TOption[]) => void;
}

interface OptionItemProps<TOption extends IAllowedOptions>
  extends ListChildComponentProps<OptionItemDataSource<TOption>> {
  ellipsisConfig?: EllipsisConfig;
}

export function OptionItem<TOption extends IAllowedOptions>({
  style,
  index,
  ellipsisConfig,
  data,
}: OptionItemProps<TOption>) {
  const item = data.filteredOptions[index];
  const checked =
    data.tempSelOptions === null || data.tempSelOptions.includes(item.id);
  const { isDark } = useCustomTheme();
  const typographyVariant = useMemo(() => {
    if (!checked) {
      return "faded";
    }
    return isDark ? "white" : "dark";
  }, [isDark, checked]);

  return (
    <ListItemContainer style={style} key={`${item.id}_${index}`}>
      <Checkbox
        value={item.id}
        checked={checked}
        onChange={(e) => {
          const newData = e.target.checked
            ? [...(data.tempSelOptions ?? []), item.id]
            : (data.tempSelOptions ?? []).filter((e: unknown) => e !== item.id);

          data.onChange(newData);
        }}
      >
        <StyledTitleContainer>
          <Title
            level={3}
            variant={typographyVariant}
            ellipsis={ellipsisConfig}
            style={{
              display: "inline-block",
              width: data.width ? data.width - 55 : "100%",
            }}
          >
            {item.name}
          </Title>
        </StyledTitleContainer>
      </Checkbox>
    </ListItemContainer>
  );
}
