import { ErrorBoundary } from "@sentry/react";
import { WellStatusType } from "apis/oag";
import { CustomSwitch } from "atoms/common";
import type { RangeType } from "atoms/DatePicker";
import { DatePicker } from "atoms/DatePicker";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { getTitleRealTimeIndicator } from "components/DetailsTopBar/utils";
import { AllFilters } from "components/Filters/AllFilters";
import { FilterTags } from "components/Filters/FilterTags";
import { useFiltersCategories } from "components/Filters/useFiltersCategories";
import { MapControlHeader } from "components/MapControlHeader";
import MapView from "components/MapView";
import { useWellsMapViewContext } from "components/MapView/useWellsMapView";
import { PDComponent } from "components/PDComponents";
import {
  RealTimeDataEnum,
  RealTimeIndicator,
} from "components/RealTimeIndicator";
import { LinkedSelectors } from "components/RigsHeader/LinkedSelectors";
import * as Styled from "components/RigsHeader/style";
import { WebGLSupportBanner } from "components/WebGLSupportBanner/WebGLSupportBanner";
import { useFilterTags } from "hooks/filters/useFilterTags";
import {
  AttributeStore,
  useLinkedWellAttributes,
} from "hooks/filters/useLinkedAttributes";
import { useCurrentUser } from "hooks/user/useCurrentUser";
import { useFilteredAllWellsContext } from "pages/AllWells/useFilteredAllWells";
import { Suspense, useCallback, useState } from "react";
import Modal from "react-modal";
import { Link, useLocation } from "react-router-dom";
import { type IFilterOptions } from "reducers/rigsCommonReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { routePaths } from "routePaths";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import { Col, Space, Tooltip } from "utils/componentLibrary";
import { range90Days, stripSlashes } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

const isAnyLoading = (isLoading: boolean[]) =>
  isLoading.some((loading) => loading);

const customStyles = {
  overlay: {
    zIndex: zIndexLayer.moon,
    backgroundColor: colors.faded_bg,
  },
  content: {
    top: "50%",
    left: "50%",
    overflow: "hidden",
    right: "auto",
    height: "700px",
    width: "82%",
    borderRadius: 6,
    bottom: "auto",
    padding: "0px!important",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
};

function Header({
  filters,
  setFilters,
  totalRigs,
}: Readonly<{
  totalRigs: number;
  filters: IFilterOptions | null;
  setFilters: (localFilters: IFilterOptions | null) => void;
}>) {
  const dispatch = useAppDispatch();
  const { data: currentUser } = useCurrentUser();

  const realTimeDataState = useAppSelector(
    (state) => state.global.realTimeData,
  );
  const periodState = useAppSelector((state) => state.rigsCommon.period);
  const allWellsIncluded = useAppSelector(
    (state) => state.rigsCommon.allWellsIncluded,
  );

  const exceptions = useAppSelector((state) => state.rigsCommon.exceptions);

  const { initialFilters } = useFiltersCategories(
    filters,
    setFilters,
    AttributeStore.rigsCommon,
  );
  const { visibleWellsList } = useFilteredAllWellsContext();

  const { area, basinName, radius, resetAll } = useWellsMapViewContext();
  const mapSelectedWells = useAppSelector(
    (state) => state.rigsCommon.selectedWellsMap,
  );

  const {
    updateFilters,
    selectedValues,
    options,
    isLoading: isLoadingObject,
  } = useLinkedWellAttributes({
    storeName: AttributeStore.rigsCommon,
  });

  const isLoading = isAnyLoading(Object.values(isLoadingObject));
  const tags = useFilterTags({
    updateFilters,
    isLoadingArr: Object.values(isLoadingObject),
    filters,
    setFilters,
    selectedValues: {
      ...selectedValues,
      wells: selectedValues.wells,
    },
    options,
    attributeStoreName: AttributeStore.rigsCommon,
  });

  const updatePeriod = useCallback(
    (period: RangeType) => {
      Track.interact("Rigs - Update Period", {
        "Start Date": period.startDate?.toISOString?.() ?? "",
        "End Date": period.endDate?.toISOString?.() ?? "",
      });

      dispatch({
        type: "RIGS_COMMON_SET_PERIOD",
        payload: period,
      });
      return true;
    },
    [dispatch],
  );

  const { themeStyle, atomThemeVariant } = useCustomTheme();

  const location = useLocation();

  const isLeaderboard =
    stripSlashes(location.pathname) === stripSlashes(routePaths.rigLeaderboard);
  const isFleetPerformance =
    stripSlashes(location.pathname) ===
    stripSlashes(routePaths.fleetPerformance);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);

  const isDisabled = !(area || basinName || radius);

  return (
    <>
      <Styled.HeaderContainerRow
        justify="space-between"
        align="middle"
        wrap={false}
      >
        <Col span={3}>
          <Title level={3} variant={atomThemeVariant} weight={500}>
            Rigs ({totalRigs})
          </Title>
        </Col>

        <Col>
          <Styled.RightContainer wrap>
            <Styled.FilterTagsContainer>
              <FilterTags tags={tags} />
            </Styled.FilterTagsContainer>

            <Styled.PaddedSpace>
              <Title level={3} variant={"faded"} weight={400}>
                Filter by
              </Title>
              <AllFilters
                filters={filters}
                setFilters={setFilters}
                attributeStoreName={AttributeStore.rigsCommon}
              />
              <LinkedSelectors storeName={AttributeStore.rigsCommon} />

              {/* Full Wells Included */}
              <Tooltip title="Full Wells Included">
                <Button
                  size="large"
                  loading={isLoading}
                  style={{
                    border:
                      !isLoading && allWellsIncluded
                        ? `1px solid ${colors.well_color}`
                        : undefined,
                  }}
                  icon={
                    <PDComponent.SvgIcon
                      name="allWellsIncluded"
                      style={{
                        color:
                          !isLoading && allWellsIncluded
                            ? colors.well_color
                            : undefined,
                      }}
                    />
                  }
                  onClick={() => {
                    Track.interact("Rigs - Full Wells Included", {
                      State: !allWellsIncluded ? "Active" : "Disabled",
                    });
                    dispatch({
                      type: "RIGS_COMMON_FULL_WELLS_INCLUDED",
                      payload: !allWellsIncluded,
                    });
                  }}
                />
              </Tooltip>
              {/* Period Filter */}
              <DatePicker
                defaultDate={range90Days}
                selection={periodState}
                onApply={updatePeriod}
              />
              <PDComponent.VerticalDivider />
            </Styled.PaddedSpace>

            <Button
              size="large"
              onClick={() => {
                Track.interact("Rigs - Map View");
                setIsMapModalOpen(true);
              }}
              icon={
                <PDComponent.SvgIcon
                  name="mapButtonRigs"
                  color={
                    area || basinName || radius ? colors.well_color : undefined
                  }
                />
              }
            />
            <PDComponent.VerticalDivider />
            <Styled.PaddedSpace>
              <Tooltip title={getTitleRealTimeIndicator(realTimeDataState)}>
                <Button
                  size="large"
                  onClick={() => {
                    Track.interact("Rigs - Real Time", {
                      State:
                        realTimeDataState === RealTimeDataEnum.ACTIVE
                          ? "Disabled"
                          : "Active",
                    });
                    dispatch({
                      type: "SET_REAL_TIME_DATA",
                      payload:
                        realTimeDataState === RealTimeDataEnum.ACTIVE
                          ? RealTimeDataEnum.DISABLED
                          : RealTimeDataEnum.ACTIVE,
                    });
                  }}
                  icon={
                    <RealTimeIndicator realTimeDataState={realTimeDataState} />
                  }
                />
              </Tooltip>
              {currentUser?.canAccessRigScorecard ? (
                <>
                  <PDComponent.VerticalDivider />
                  <Space>
                    <Styled.StyledTitle variant={atomThemeVariant}>
                      Exceptions
                    </Styled.StyledTitle>
                    <CustomSwitch
                      $isChecked={exceptions}
                      checked={exceptions}
                      onChange={(e) => {
                        dispatch({
                          type: "RIGS_COMMON_EXCEPTIONS_INCLUDED",
                          payload: e,
                        });
                        Track.interact("Rigs - Exceptions", {
                          Value: e ? "On" : "Off",
                        });
                      }}
                    />
                  </Space>
                  <PDComponent.VerticalDivider />

                  <Styled.Buttons size="large">
                    <Link to={routePaths.rigLeaderboard}>
                      <Button
                        shape="round"
                        size="large"
                        type={isLeaderboard ? "primary" : "default"}
                        onClick={() => {
                          Track.clickEvent("Leaderboard Button");

                          dispatch({
                            type: "RESET_RIGS_COMMON_FILTERS_90_DAYS",
                            payload: {
                              ...initialFilters,
                              wellStatus: [
                                {
                                  id: WellStatusType.Completed,
                                  value: WellStatusType.Completed,
                                },
                              ],
                            },
                          });
                        }}
                      >
                        Leaderboard
                      </Button>
                    </Link>

                    <Link to={routePaths.fleetPerformance}>
                      <Button
                        shape="round"
                        size="large"
                        type={isFleetPerformance ? "primary" : "default"}
                        onClick={() => {
                          Track.clickEvent("Fleet Performance Button");
                          dispatch({
                            type: "RESET_RIGS_COMMON_FILTERS_90_DAYS",
                          });
                        }}
                      >
                        Fleet Performance
                      </Button>
                    </Link>
                  </Styled.Buttons>
                </>
              ) : null}
            </Styled.PaddedSpace>
          </Styled.RightContainer>
        </Col>
      </Styled.HeaderContainerRow>
      <Modal
        appElement={document.getElementById("root") as HTMLElement}
        onRequestClose={() => {
          if (!mapSelectedWells) resetAll();
          setIsMapModalOpen(false);
        }}
        style={{
          content: {
            ...customStyles.content,
            backgroundColor: colors.shutter_black,
            display: isMapModalOpen ? "block" : "none",
          },
          overlay: {
            ...customStyles.overlay,
            display: isMapModalOpen ? "block" : "none",
          },
        }}
        // don t unmount the component, to show the previous state inside the modal
        isOpen={true}
      >
        <Suspense fallback={null}>
          <Styled.HeaderMap>
            <Title level={3} variant="faded">
              Map Interaction
            </Title>
            <MapControlHeader
              trackingTitle={
                isLeaderboard ? "Rig Leaderboard" : "Rig Fleet Performance"
              }
              isDark
            />
            <Tooltip
              title={
                isDisabled
                  ? "Not available until a Map Interaction is selected"
                  : ""
              }
            >
              {/* tooltip element does not work if the button is not wrapped in another element */}
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>
                <Styled.ResetButton
                  disabled={isDisabled}
                  onClick={() => {
                    dispatch({
                      type: "RIGS_COMMON_SET_WELLS_MAP",
                      payload: {
                        selectedWells: null,
                      },
                    });
                    setIsMapModalOpen(false);
                    resetAll();
                  }}
                >
                  Reset
                </Styled.ResetButton>
              </>
            </Tooltip>
            <Tooltip
              title={
                isDisabled
                  ? "Not available until a Map Interaction is selected"
                  : ""
              }
            >
              {/* tooltip element does not work if the button is not wrapped in another element */}
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>
                <Styled.ApplyButton
                  type="primary"
                  disabled={isDisabled}
                  onClick={() => {
                    dispatch({
                      type: "RIGS_COMMON_SET_WELLS_MAP",
                      payload: {
                        selectedWells: visibleWellsList as number[],
                      },
                    });
                    setIsMapModalOpen(false);
                  }}
                >
                  Apply
                </Styled.ApplyButton>
              </>
            </Tooltip>
            <PDComponent.SvgIcon
              color={themeStyle.colors.faint_typography}
              height={24}
              width={24}
              style={{
                padding: "0px 12px",
                marginRight: 12,
              }}
              onClick={() => {
                setIsMapModalOpen(false);
              }}
              name="close"
            />
          </Styled.HeaderMap>
          <Suspense fallback={null}>
            <ErrorBoundary fallback={<WebGLSupportBanner />}>
              <MapView isRigCommonMap={true} isMapOpen={isMapModalOpen} />
            </ErrorBoundary>
          </Suspense>
        </Suspense>
      </Modal>
    </>
  );
}

export default Header;
